import { Box, Button, Collapse, Icon, Text, Tooltip } from "@chakra-ui/react"
import { FORM_ERROR, FormState as FinalFormState } from "final-form"
import { useContext, useEffect, useState } from "react"
import { Field, FormSpy } from "react-final-form"
import { SiMaildotru } from "react-icons/si"

import {
  useCancelVerification,
  useCheckVerification,
  useRequestVerification,
} from "app/contact-details-verifications/hooks"
import Form from "app/core/components/Form"
import LabeledTextField from "app/core/components/LabeledTextField"

import { ConnectionContainerButton, getStatusIcon } from "./status"

import { OrgContext } from "./orgPage"
import { UserContext } from "app/core/layouts/Layout"
import { ConnectionOptions } from "./connections/ConnectionOptions"

const channel = "email"

interface FormState {
  to: string
  code: string
}

const EmailVerificationButton: React.FC<{
  verifiedEmail?: string
}> = ({ verifiedEmail }) => {
  const [to, setTo] = useState("")
  const [isOpen, setIsOpen] = useState(false)
  const [isPending, setIsPending] = useState(false)
  const [isVerified, setIsVerified] = useState(!!verifiedEmail)
  const [currentInstanceSid, setCurrentInstanceSid] = useState("")

  const { refetch: refetchUser } = useContext(UserContext)!
  const { refetch: refetchOrg } = useContext(OrgContext)!

  const [
    checkVerification,
    { isLoading: isCheckVerificationLoading, isSuccess: isCheckVerificationSuccess },
  ] = useCheckVerification()
  const [cancelVerification] = useCancelVerification()
  const [requestVerification, { isLoading: isRequestVerificationLoading }] =
    useRequestVerification()

  useEffect(() => {
    setIsVerified(!!verifiedEmail)
  }, [verifiedEmail])

  useEffect(() => {
    async function refresh() {
      await Promise.all([refetchUser(), refetchOrg()])
    }

    if (isCheckVerificationSuccess && isVerified) {
      refresh()
    }
  }, [isCheckVerificationSuccess, isVerified, refetchOrg, refetchUser])

  const { icon, message } = getStatusIcon(isVerified ? "APPROVED" : undefined)

  const handleFormChange = ({ modified, values }: FinalFormState<FormState>) => {
    if (!!modified?.to) {
      setTo(values.to)
    }
  }

  const handleFormClick = (e) => {
    e.stopPropagation()
  }

  const handleFormSubmit = async ({ code, to }, form) => {
    const response = await checkVerification({ code, to, channel })

    if (response?.status !== "approved") {
      return { [FORM_ERROR]: "Incorrect code" }
    }

    form.reset()

    setIsPending(false)
    setIsVerified(true)
    setIsOpen(false)
  }

  const handleRequestCode = async () => {
    if (isPending) {
      await cancelVerification({ instanceSid: currentInstanceSid })

      setCurrentInstanceSid("")
      setIsPending(false)

      return
    }

    const request = await requestVerification({ channel, to })

    setCurrentInstanceSid(request?.sid!)
    setIsPending(true)
  }

  return (
    <>
      <ConnectionContainerButton
        as="div"
        borderRadius="4px"
        colorScheme="orange"
        cursor="pointer"
        d="flex"
        flexDirection="row"
        flexWrap="wrap"
        fontSize={["1.2em", "1.4em", "1.6em"]}
        h="auto"
        isFullWidth={true}
        leftIcon={<SiMaildotru />}
        onClick={() => {
          setIsOpen((isOpen) => !isOpen)
        }}
        px={3}
        py="0.4em"
        size="lg"
        variant="outline"
      >
        <Text flex="1" textAlign="left">
          {"Email"}
        </Text>
        {isVerified && (
          <Box alignItems="center" display="flex" flexBasis="0" flexDirection="row" width="100%">
            <Text fontSize={16} fontWeight="normal" pr="0.5em">
              {verifiedEmail ?? to}
            </Text>
            {icon && (
              <Tooltip aria-label={message} label={message}>
                <Box
                  as="span"
                  className={(!isOpen && isVerified && "hide-right-icon fade-in") || ""}
                >
                  <Icon as={icon} boxSize="0.7em" mt="-0.2em" />
                </Box>
              </Tooltip>
            )}
          </Box>
        )}
        <Box flexBasis="100%" h={0}></Box> {/* similar to float break */}
        <Collapse in={isOpen} startingHeight="0em" style={{ width: "100%" }}>
          <Box fontSize={[12, 14]} mt={1} pl={[1, 1, 1.5]} w="full">
            {!isVerified && (
              <Form onClick={handleFormClick} onSubmit={handleFormSubmit}>
                <Box alignItems="end" display="flex">
                  <LabeledTextField
                    disabled={isPending}
                    name="to"
                    outerProps={{ isRequired: true }}
                    type="email"
                    label="Email address"
                  />
                  <Button ml={2} onClick={handleRequestCode}>
                    {isRequestVerificationLoading
                      ? "Sending..."
                      : isPending
                      ? "Cancel"
                      : "Get code"}
                  </Button>
                </Box>

                {isPending && (
                  <>
                    <LabeledTextField
                      label="Verification code"
                      name="code"
                      outerProps={{ isRequired: true }}
                      type="text"
                    />

                    <Button type="submit" w="full">
                      {isCheckVerificationLoading ? "Submitting..." : `Enter code sent to ${to}`}
                    </Button>
                  </>
                )}

                <FormSpy
                  subscription={{ modified: true, values: true }}
                  onChange={handleFormChange}
                />
              </Form>
            )}
            {isVerified && <ConnectionOptions connection={"email"} color={"orange"} />}
          </Box>
        </Collapse>
      </ConnectionContainerButton>
    </>
  )
}

export default EmailVerificationButton
