export function serializeOAuthState(state: CultOAuth2State): string {
  return Buffer.from(JSON.stringify(state)).toString("base64")
}

export function deserializeOAuthState(state: string): CultOAuth2State {
  const b64decoded = Buffer.from(state, "base64").toString("utf8")
  return JSON.parse(b64decoded) as CultOAuth2State
}

export type CultOAuth2State = {
  orgId?: string
  googleOrgId?: string
  isGoogleOrgAdmin?: boolean
}
