import { Divider, Text, TextProps, VStack, Button, useToast, Box, Icon } from "@chakra-ui/react"
import { ThemeTypings } from "@chakra-ui/styled-system"
import { CurrentUser } from "app/users/queries/getCurrentUser"
import { useRouter, useMutation } from "blitz"
import { useContext, Fragment } from "react"
import { IconType } from "react-icons"
import { FaHandshake } from "react-icons/fa"
import { SiDiscord, SiEthereum, SiTwitter, SiMaildotru, SiGoogle, SiTelegram } from "react-icons/si"
import { AiOutlineForm } from "react-icons/ai"
import { BsPhoneVibrate } from "react-icons/bs"
import { CurrentOrg, CurrentOrgConnection } from "../../queries/getOrg"
import { UserContext } from "app/core/layouts/Layout"
import { OrgContext } from "../orgPage"
import { getLoginLink } from "app/lib/urlUtils"
import { AuthConnection } from "app/users/utils"
import JoinOrg from "app/users/mutations/joinOrg"
import { ProviderButton } from "./ProviderButton"
import LoginWithTelegram from "app/users/mutations/loginWithTelegram"
import { NiftyGatewayLogo } from "./NiftyGatewayLogo"
import { Connection } from "app/lib/requirements/types"
import { ConnectionContainerButton } from "../status"
import { FiArrowUpRight } from "react-icons/fi"

type ConnectionTuple = [AuthConnection, CurrentOrgConnection]
type ConnectionGroups = {
  notConnected: ConnectionTuple[]
  connected: ConnectionTuple[]
}

export const Subtitle = (props: TextProps) => (
  <Text
    pr={2}
    opacity={0.7}
    alignSelf="flex-start"
    fontSize={[14, 15, 17]}
    fontWeight="medium"
    as="i"
    mb={0}
    {...props}
  />
)

export const AuthProviders = ({
  user,
  org,
  walletConnectingMessage,
  walletSignin,
}: {
  user?: CurrentUser
  org: CurrentOrg
  walletConnectingMessage: string | null
  walletSignin: () => void
}) => {
  const router = useRouter()
  const [joinOrgMutation, { isLoading: joinOrgLoading }] = useMutation(JoinOrg)
  const [loginWithTelegram] = useMutation(LoginWithTelegram)

  const { refetch: refetchUser } = useContext(UserContext)!
  const { refetch: refetchOrg } = useContext(OrgContext)!

  const showJoinButton = !!user && !org.userIsMember

  const toast = useToast()

  const joinOrg = async () => {
    await joinOrgMutation()
    await refetchOrg()
    await refetchUser()
  }

  const tweetReqLink =
    (!!user?.eth?.[0] &&
      !user?.twitter?.[0] &&
      org.connections?.twitter?.requirements?.find((req) => req.name === "userHasTweetedX")
        ?.links[0]) ||
    undefined

  const sortedConnections = Object.entries(org.connections).sort(([a, _a], [b, _b]) =>
    tweetReqLink && a === "twitter" ? -1 : a < b ? -1 : a > b ? 1 : 0
  ) as ConnectionTuple[]

  const groups: ConnectionGroups = {
    notConnected: [],
    connected: [],
  }
  for (const conn of sortedConnections) {
    const group: keyof ConnectionGroups = user?.[conn[0]]?.[0] ? "connected" : "notConnected"
    groups[group].push(conn)
  }

  const req = ([connectionType, { status }]: ConnectionTuple) => {
    const isEth = connectionType === "eth"
    const isGoogle = connectionType === "google"
    const isTelegram = connectionType === "telegram"

    const { name, color, icon: ConnectionIcon, url } = connectionToMeta[connectionType]
    if (!name) console.error("no connection meta for " + connectionType)

    const loginClick = async (args?) => {
      if (isEth) {
        if (!walletConnectingMessage)
          throw new Error("Eth login click triggered, should be handled by rainbow")
      } else if (isTelegram) {
        try {
          await loginWithTelegram({ user: args, orgId: org.id })
        } catch (e) {
          const isCancelledError = e && e.hasOwnProperty("silent")
          if (isCancelledError) {
            //cancelled in flight mutation... do nothing
          } else {
            toast({
              status: "error",
              title: `Could not connect to Telegram!`,
              duration: 6000,
              isClosable: true,
            })
          }
        } finally {
          await Promise.all([refetchUser(), refetchOrg()])
        }
      } else {
        const path = router.asPath.split("?")[0]
        const token = router.query?.["token"]

        window.location.href = getLoginLink(
          connectionType as Connection,
          org.id,
          path,
          token as string,
          args
        )
      }

      const path = router.asPath.split("?")[0]
      const token = router.query?.["token"]
      console.log("got args", args)
      window.location.href = getLoginLink(
        connectionType as Connection,
        org.id,
        path,
        token as string,
        args
      )
    }

    return (
      <Fragment key={connectionType}>
        {!isGoogle && (
          <>
            <ProviderButton
              user={user}
              connection={org.connections[connectionType]}
              connectionName={connectionType}
              login={loginClick}
              status={status}
              loading={isEth ? !!walletConnectingMessage : false}
              walletSignin={walletSignin}
              noWalletDisconnect={org.noWalletDisconnect}
            />
            {walletConnectingMessage && isEth && (
              <Subtitle textAlign="left" pb={3} fontSize={16} key="sub">
                {walletConnectingMessage}
              </Subtitle>
            )}
          </>
        )}
        {isGoogle &&
          (org.googleOrgs || [{ id: undefined, name: undefined }]).map((googleOrg) => (
            <Fragment key={googleOrg?.id ?? "google"}>
              <ProviderButton
                user={user}
                connection={org.connections[connectionType]}
                connectionName={connectionType}
                login={() => loginClick(googleOrg?.id ? { googleOrgId: googleOrg.id } : undefined)}
                status={status}
                loading={isEth ? !!walletConnectingMessage : false}
                walletSignin={walletSignin}
                overrideName={googleOrg?.name ? googleOrg.name : undefined}
              />
            </Fragment>
          ))}
      </Fragment>
    )
  }
  // const notApprovedOrConnected = [...groups.notConnected, ...groups.notApproved]

  // if there is no discord requirement but entry is approved and there is a guild
  const addDiscord = !org.connections.discord && !!org.entryDecision && !!org.guildId
  const addDiscordAndNotConnected = addDiscord && !user?.discord

  if (addDiscord) {
    ;(addDiscordAndNotConnected ? groups.notConnected : groups.connected).push([
      "discord",
      {
        status: "APPROVED",
      },
    ])
  }

  const showDivider = !!groups.connected.length && !!groups.notConnected.length

  const topSectionPrompt = org.vui
    ? null
    : !tweetReqLink
    ? addDiscordAndNotConnected
      ? "Approved - connect discord to join:"
      : groups.notConnected.length
      ? org.entryDecision
        ? "Connect to get roles:"
        : "Connect to join:"
      : null
    : null

  return (
    <VStack
      flex="2"
      d="flex"
      flexDir="column"
      justifyContent="space-between"
      alignItems="stretch"
      height="100%"
      pb={topSectionPrompt ? 10 : 0}
    >
      {topSectionPrompt && <Subtitle>{topSectionPrompt}</Subtitle>}
      {!showJoinButton && tweetReqLink ? <TweetButton link={tweetReqLink} /> : null}
      {showJoinButton ? (
        <Button
          colorScheme={"green"}
          leftIcon={<FaHandshake />}
          isFullWidth={true}
          size="lg"
          py="0.4em"
          h="auto"
          fontSize={["1.2em", "1.4em", "1.6em"]}
          variant={"solid"}
          borderRadius="4px"
          justifyContent={"left"}
          px={3}
          onClick={joinOrg}
          d="flex"
          flexWrap="wrap"
          flexDirection="row"
          isLoading={joinOrgLoading}
          as="div"
          cursor="pointer"
        >
          Join
        </Button>
      ) : (
        groups.notConnected.map(req)
      )}
      {showDivider && (
        <Divider borderColor="gray.400" mt="1rem !important" mb="0.5rem !important" />
      )}
      {groups.connected.map(req)}
    </VStack>
  )
}

export const connectionToMeta: Record<
  Connection,
  {
    icon: IconType
    name: string
    color: ThemeTypings["colorSchemes"]
    url?: (profile) => string
  }
> = {
  twitter: {
    icon: SiTwitter,
    name: "Twitter",
    color: "twitter",
    //TODO: dumb for now
    url: (profile: NonNullable<CurrentUser["twitter"][0]>) =>
      `https://twitter.com/${profile.username}`,
  },
  discord: {
    icon: SiDiscord,
    name: "Discord",
    color: "discord" as ThemeTypings["colorSchemes"],
    // url: (profile: NonNullable<CurrentUser["discord"]>) =>
    //   `https://discord.com/users/${profile.username}`,
  },
  telegram: {
    icon: SiTelegram,
    name: "Telegram",
    color: "telegram",
  },
  eth: {
    icon: SiEthereum,
    name: "Ethereum",
    color: "gray",
    //TODO: dumb for now
    url: (profile: NonNullable<CurrentUser["eth"][0]>) =>
      `https://etherscan.io/address/${profile.address}`,
  },
  nifty: {
    icon: NiftyGatewayLogo,
    name: "Nifty Gateway",
    color: "nifty" as ThemeTypings["colorSchemes"],
    //TODO: dumb for now
    url: (profile: NonNullable<CurrentUser["nifty"][0]>) =>
      `https://niftygateway.com/profile/${profile.username}`,
  },
  conde: {
    // @ts-ignore
    icon: Fragment,
    name: "Vogue",
    color: "gray",
    //TODO: dumb for now
    // url: (profile: NonNullable<CurrentUser["nifty"][0]>) =>
    //   `https://niftygateway.com/profile/${profile.username}`,
  },
  tally: {
    icon: AiOutlineForm,
    name: "Form",
    color: "orange" as ThemeTypings["colorSchemes"],
  },
  email: {
    icon: SiMaildotru,
    name: "Email",
    color: "orange" as ThemeTypings["colorSchemes"],
  },
  sms: {
    icon: BsPhoneVibrate,
    name: "Phone Number",
    color: "red" as ThemeTypings["colorSchemes"],
  },
  google: {
    icon: SiGoogle,
    name: "Google",
    color: "red",
    url: (profile: NonNullable<CurrentUser["google"][0]>) => `https://myaccount.google.com/`,
  },
}

export const TweetButton = ({ link }: { link: [string, string] }) => (
  <>
    <ConnectionContainerButton
      leftIcon={<Icon as={connectionToMeta.twitter.icon} />}
      colorScheme={connectionToMeta.twitter.color}
      isFullWidth={true}
      size="lg"
      py="0.4em"
      h="auto"
      fontSize={["1.2em", "1.4em", "1.6em"]}
      variant="outline"
      borderRadius="4px"
      justifyContent={true ? "left" : "center"}
      bg={true ? connectionToMeta.twitter.color + ".50" : undefined}
      px={3}
      href={link[1]}
      target="_blank"
      d="flex"
      flexWrap="wrap"
      flexDirection="row"
      as="a"
      cursor="pointer"
    >
      <Text flex="1" textAlign="left" fontWeight="normal">
        {link[0]}
      </Text>
      <Text flex="0" textAlign="left" marginTop={-1}>
        <Icon as={FiArrowUpRight} />
      </Text>
    </ConnectionContainerButton>
    <Box
      position="relative"
      display="flex"
      textAlign="center"
      justifyContent="center"
      flexDirection="row"
      alignItems="center"
    >
      <Text
        // color="gray.400"
        bg="black"
        opacity={0.7}
        zIndex={5}
        paddingX={3}
        fontSize={[12, 16]}
        fontWeight="bold"
        position="relative"
        marginBottom={0}
      >
        then
      </Text>
    </Box>
  </>
)
