import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Image,
  Progress,
  Stack,
  Text,
  TextProps,
  useToast,
} from "@chakra-ui/react"

import useWalletSignin from "app/core/hooks/useWalletSignin"
import { UserContext } from "app/core/layouts/Layout"
import { useRefreshMembership } from "app/users/hooks"
import { useMutation, useQuery, useRouter } from "blitz"
import { useContext } from "react"
import { FiRefreshCw } from "react-icons/fi"
import { CurrentOrg } from "../queries/getOrg"
import { AuthProviders } from "./connections/index"
import { OpenDiscordButton } from "./DiscordCard"
import { Loader } from "./status"

import styled from "@emotion/styled"
import { MemberCheckStatus } from "@prisma/client"
import VerifyCaptcha from "app/captcha-completions/mutations/verifyCaptcha"
import { CurrentUser } from "app/users/queries/getCurrentUser"

import logout from "app/auth/mutations/logout"
import { compact } from "lodash"
import ToogleUserRolePropagation, {
  ToogleUserRolePropagationReturnType,
} from "../mutations/toogleUserRolePropagation"

export const Header = (props: TextProps) => (
  <Text pr={2} fontSize={["xl", "2xl", "2xl"]} fontWeight="bold" mb={1} {...props} />
)

export const ClubMembershipStatus = ({
  status,
  user,
  org,
}: {
  status: MemberCheckStatus | undefined
  user: CurrentUser
  org: CurrentOrg
}) => {
  if (status === MemberCheckStatus.APPROVED) {
    return (
      <Box borderColor="gray.500" borderWidth="1px" p={5} borderRadius={6}>
        <Text fontWeight="medium" fontSize={16} textAlign="center" mb={8}>
          Verification successful!
          <br />
          You now have access to the full Vogue Club Discord server. Return to the Discord using the
          link below.
        </Text>

        <Box>{!!org.guildId && <OpenDiscordButton orgId={org.id} text="Return to Discord" />}</Box>
      </Box>
    )
  } else if (status === MemberCheckStatus.DENIED) {
    return (
      <Box borderColor="gray.500" borderWidth="1px" p={5} borderRadius={10}>
        <Text fontWeight="medium" fontSize={16} textAlign="center">
          There is not a Vogue Club membership associated with this email.
        </Text>

        <Text fontWeight="medium" fontSize={13} textAlign="center" opacity={0.6}>
          Please check you are using the correct email.
        </Text>

        <Text fontWeight="medium" fontSize={16} textAlign="center" mt={8}>
          Become a Vogue Club member to get access to the community.
        </Text>

        <Button
          mt={2}
          as="a"
          href="https://www.vogue.com/vogue-club"
          w="full"
          size="lg"
          target="blank"
          borderWidth={1}
        >
          Subscribe to Vogue Club
        </Button>

        <Box opacity={0.7} mt={8}>
          <Text fontWeight="medium" fontSize={16} textAlign="center" mb={2}>
            If you believe this is an error, please visit the #support channel in Discord.
          </Text>
          {!!org.guildId && <OpenDiscordButton orgId={org.id} text="Return to Discord" />}
        </Box>
      </Box>
    )
  }
  return null
}

export default function OrgLogin({
  org,
  refetch: refetchOrg,
  parentIsLoading,
}: {
  org: CurrentOrg
  parentIsLoading: boolean
  refetch: ReturnType<typeof useQuery>[1]["refetch"]
}) {
  const [logoutMutation] = useMutation(logout)
  // const { toggleColorMode } = useColorMode()
  const router = useRouter()
  const { user, refetch: refetchUser, isHuman } = useContext(UserContext)!

  const [refreshMembership, { isLoading: refreshMembershipLoading }] = useRefreshMembership()
  const membershipChecking = org.isChecking
  const isLoading = refreshMembershipLoading || membershipChecking || parentIsLoading

  const { walletSignin, waitingForSign, addWalletIsLoading } = useWalletSignin()

  const isFullyConnected = !!(user?.discord?.[0] && user?.conde?.[0])

  const walletConnectingMessage = waitingForSign
    ? "Check your wallet to sign."
    : addWalletIsLoading
    ? "Connecting wallet..."
    : null

  const checkMembership = async () => {
    try {
      await refreshMembership({ orgId: org.id })
      await refetchOrg()
    } catch (e) {
      console.error(e)
    }
  }

  const clubRole = org.roles.find((role) =>
    role.requirements?.find((r) => r.name === "inVogueClub")
  )
  const clubRequirement = clubRole?.requirements?.find((r) => r.name === "inVogueClub")

  return (
    <Box
      flex="1"
      display="flex"
      flexDir="column"
      justifyContent="center"
      alignItems="center"
      p={"0"}
    >
      <Box maxW="xl">
        <Box pb="2">
          <Box
            w="full"
            flexDir="row"
            justifyContent="center"
            display="flex"
            alignItems="center"
            pt={16}
            pb={5}
          >
            <Image alt="Vogue Club Logo" src="/logo-vogue-club.svg" ml={-4} />
          </Box>

          <Box
            fontWeight="light"
            marginBottom="1em"
            lineHeight="1.2em"
            fontSize={[12, 13, 15]}
            py={0}
          >
            {compact([
              // !!org.approximateMemberCount && (
              //   <>
              //     <Text fontWeight="medium" as="span" fontSize="0.9em">
              //       {org.approximateMemberCount}
              //     </Text>{" "}
              //     members
              //   </>
              // ),
              // !!org.approximatePresenceCount && (
              //   <>
              //     <Text fontWeight="medium" as="span" fontSize="0.9em">
              //       {org.approximatePresenceCount}
              //     </Text>{" "}
              //     online
              //   </>
              // ),
              // !!org.website && (
              //   <a href={org.website} target="_blank" rel="noreferrer">
              //     <Icon as={FiLink} mr="0.3em" fontSize="0.7em" />
              //     <ExternalLink fontWeight="medium" as="span" fontSize="0.9em">
              //       website
              //     </ExternalLink>
              //   </a>
              // ),
              // !!org.editLink && (
              //   <a href={org.editLink} target="_blank" rel="noreferrer">
              //     <Icon as={FiEdit} mr="0.3em" fontSize="0.7em" />
              //     <ExternalLink fontWeight="medium" as="span" fontSize="0.9em">
              //       edit
              //     </ExternalLink>
              //   </a>
              // ),
            ]).reduce<JSX.Element[]>(
              (jsx, el, index) => (index === 0 ? [el] : jsx.concat([<> / {el}</>])),
              []
            )}
          </Box>

          {org.description && (
            <Text
              fontWeight="light"
              borderLeftColor="gray.500"
              borderLeftStyle="solid"
              borderLeftWidth="2px"
              paddingLeft="1em"
              lineHeight="1.2em"
            >
              {org.description}
            </Text>
          )}
        </Box>

        <Progress
          size="xs"
          isIndeterminate={isLoading}
          mb={6}
          opacity={isLoading ? 1 : 0}
          colorScheme="gray"
          transition={isLoading ? "opacity 1s" : "opacity 0"}
        />

        {user && isFullyConnected && (
          <Box mb={4}>
            <ClubMembershipStatus org={org} user={user} status={clubRequirement?.check?.status} />
          </Box>
        )}

        {user ? (
          <Accordion pt={8} defaultIndex={!isFullyConnected ? [0] : undefined} allowMultiple>
            <AccordionItem borderBottom={0}>
              <h2>
                <AccordionButton>
                  <Box as="span" flex="1" textAlign="left" py={3} opacity={0.6}>
                    Your Accounts
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel py={4}>
                <Box alignItems="center" mb={8}>
                  <AuthProviders
                    user={user}
                    org={org}
                    walletConnectingMessage={walletConnectingMessage}
                    walletSignin={walletSignin}
                  />
                </Box>

                <Button
                  w="full"
                  colorScheme="gray"
                  onClick={checkMembership}
                  rightIcon={membershipChecking ? <Loader /> : <FiRefreshCw />}
                  disabled={isLoading}
                  mb={5}
                >
                  Check my access
                </Button>

                <Button
                  onClick={async () => {
                    await logoutMutation()
                    delete router.query.authError
                  }}
                  w="full"
                >
                  Logout
                </Button>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        ) : (
          <AuthProviders
            user={user}
            org={org}
            walletConnectingMessage={walletConnectingMessage}
            walletSignin={walletSignin}
          />
        )}

        {/* {!!org.roles.length && (
          <Box>
            <HStack mb={1}>
              <Header flex="1">{!!approvedRoles.length && "Your "}Roles</Header>
              {user && (
                <Button
                  size={"sm"}
                  colorScheme="gray"
                  onClick={checkMembership}
                  rightIcon={membershipChecking ? <Loader /> : <FiRefreshCw />}
                  disabled={isLoading}
                >
                  Check
                </Button>
              )}
            </HStack>
            {approvedRoles.map((role) => (
              <RoleButton
                key={role.id}
                role={role}
                toogleUserRolePropagation={toogleUserRolePropagation}
                isOrgMember={org.userIsMember}
                userRolePropagationIsEnabled={userRolePropagationIsEnabled}
              />
            ))}

            {otherRoles.length && approvedRoles.length ? (
              <Header flex="1" mt={6} mb={1}>
                Other Roles
              </Header>
            ) : null}

            {otherRoles.map((role) => (
              <RoleButton
                key={role.id}
                role={role}
                toogleUserRolePropagation={toogleUserRolePropagation}
                isOrgMember={org.userIsMember}
                userRolePropagationIsEnabled={userRolePropagationIsEnabled}
              />
            ))}
          </Box>
        )} */}
      </Box>
    </Box>
  )
}

const ExternalLink = styled(Text)`
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
`
