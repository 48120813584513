import { useColorMode } from "@chakra-ui/react"
import { CurrentOrgRole } from "../queries/getOrg"
import { getSubTheme } from "./ensComponents"
import { Box, Text } from "@chakra-ui/react"
import { ConnectedDot } from "./ensComponents"
import { MemberCheckStatus } from "db"

export const RoleRow: React.FC<{
  role: CurrentOrgRole
}> = ({ role, ...rest }) => {
  // const { colorMode } = useColorMode()
  // const { cardBackground, grayTextColour } = getSubTheme(colorMode)
  // const [refreshRole] = useMutation(RefreshRole)
  const { status, requirements, name, color, id, isEntryRole } = role
  // const { icon, message } = getStatusIcon(status)

  const approved = status === MemberCheckStatus.APPROVED

  return (
    <Box
      bg="#111111"
      _hover={{ bg: "#161616" }}
      d="flex"
      py={4}
      px={6}
      alignItems="center"
      borderRadius={12}
      w="full"
    >
      <Box bg={color} h={6} w={6} borderRadius={4} mr={4}></Box>
      <Box flex="1">
        <Box d="flex" flexDir={["column", "row"]}>
          <Text fontWeight="bold" fontSize={20} lineHeight={6} flex={1}>
            {name}
          </Text>

          <Box alignItems="center" d="flex" mt={1} ml={[-2, 0]}>
            <ConnectedDot connected={approved} />
            <Text pl={2} opacity={approved ? 1 : 0.6}>
              {approved ? "You have this role!" : "You do not have this role."}
            </Text>
          </Box>
        </Box>
        {requirements?.map((req) => (
          <Text key={req.id} fontWeight="normal" fontSize={16} color="whiteAlpha.600">
            {req.description}
          </Text>
        ))}
      </Box>
    </Box>
  )
}
