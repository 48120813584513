import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  Image,
  Link,
  Stack,
  Text,
  TextProps,
  useToast,
  Progress,
  Divider,
} from "@chakra-ui/react"

import { TallyLink } from "./TallyLink"

import { UserContext } from "app/core/layouts/Layout"
import { useRefreshMembership } from "app/users/hooks"
import { useMutation, useQuery } from "blitz"
import { useContext, useEffect, useState } from "react"
import { FiEdit, FiLink, FiRefreshCw } from "react-icons/fi"
import { SiDiscord } from "react-icons/si"
import { CurrentOrg, CurrentOrgRole } from "../queries/getOrg"
import { AuthProviders } from "./connections/index"
import { OpenDiscordButton } from "./DiscordCard"
import { RoleButton } from "./RoleButton"
import { Loader } from "./status"
import useWalletSignin from "app/core/hooks/useWalletSignin"

import styled from "@emotion/styled"
import { MemberCheckStatus } from "@prisma/client"
import VerifyCaptcha from "app/captcha-completions/mutations/verifyCaptcha"
import { CurrentUser } from "app/users/queries/getCurrentUser"
import BrightIDVerificationLink from "./BrightIDVerificationLink"
import Captcha from "./captcha"
import EmailVerificationButton from "./EmailVerificationButton"
import SignedOutDiscordInvite from "./SignedOutDiscordInvite"
import SmsVerificationButton from "./SmsVerificationButton"
import { compact } from "lodash"
import ToogleUserRolePropagation, {
  ToogleUserRolePropagationReturnType,
} from "../mutations/toogleUserRolePropagation"
import { OrgNotionPages } from "./OrgNotionPages"
import { UserHeader } from "app/core/components/UserHeader"

export const Header = (props: TextProps) => (
  <Text pr={2} fontSize={["xl", "2xl", "2xl"]} fontWeight="bold" mb={1} {...props} />
)

export default function OrgLogin({
  org,
  refetch: refetchOrg,
  parentIsLoading,
}: {
  org: CurrentOrg
  parentIsLoading: boolean
  refetch: ReturnType<typeof useQuery>[1]["refetch"]
}) {
  const { user, refetch: refetchUser, isHuman } = useContext(UserContext)!
  const toast = useToast()

  const [toogleUserRolePropagationMutation, { isLoading: isChangingUserRoleVisibility }] =
    useMutation(ToogleUserRolePropagation)

  const toogleUserRolePropagation = async (roleId) => {
    const response: ToogleUserRolePropagationReturnType = await toogleUserRolePropagationMutation({
      roleId,
    })
    if (response.success) {
      toast({
        status: "success",
        title: "Visibility updated",
        duration: 3000,
        isClosable: true,
      })
      await refetchOrg()
    } else
      toast({
        status: "error",
        title: "Error updating visibility",
        duration: 3000,
        isClosable: true,
      })
  }

  const [verifyCaptcha] = useMutation(VerifyCaptcha)

  const [refreshMembership, { isLoading: refreshMembershipLoading }] = useRefreshMembership()
  const membershipChecking = org.isChecking
  const isLoading =
    refreshMembershipLoading ||
    membershipChecking ||
    parentIsLoading ||
    isChangingUserRoleVisibility

  const { walletSignin, waitingForSign, addWalletIsLoading } = useWalletSignin()

  const [captchaToken, setCaptchaToken] = useState<string | null>(null)

  const onCaptchaTokenChanged = (token: string | null) => {
    setCaptchaToken(token)
  }

  useEffect(() => {
    async function verify(token) {
      await verifyCaptcha({ captchaToken: token, orgId: org.id })
      await Promise.all([refetchUser(), refetchOrg()])
    }
    if (captchaToken) {
      verify(captchaToken)
    }
  }, [captchaToken, org.id, verifyCaptcha, refetchUser, refetchOrg])

  const walletConnectingMessage = waitingForSign
    ? "Check your wallet to sign."
    : addWalletIsLoading
    ? "Connecting wallet..."
    : null

  const checkMembership = async () => {
    try {
      await refreshMembership({ orgId: org.id })
      await refetchOrg()
    } catch (e) {
      console.error(e)
    }
  }

  const { true: approvedRoles, false: otherRoles } = org.roles.reduce(
    (groups, role) => {
      groups[(role.status === MemberCheckStatus.APPROVED).toString()].push(role)
      return groups
    },
    { true: [] as CurrentOrgRole[], false: [] as CurrentOrgRole[] }
  )

  const userRolePropagationIsEnabled = org.features.includes("userRolePropagation")

  return (
    <Box flex="1" d="flex" flexDir="column" justifyContent="center" p={"0"}>
      <UserHeader user={user} isLoading={parentIsLoading} />

      <Box>
        <Box pb="2">
          <Heading as="h1" fontSize={["2em", "4em", "5em"]} flex="1" my="0">
            <Text fontSize="1em" display="inline" letterSpacing="-0.05em" mr="0.1em">
              {org.name}
            </Text>
          </Heading>
          <Box
            fontWeight="light"
            marginBottom="1em"
            lineHeight="1.2em"
            fontSize={[12, 13, 15]}
            py={0}
          >
            {compact([
              !!org.approximateMemberCount && (
                <>
                  <Text fontWeight="medium" as="span" fontSize="0.9em">
                    {org.approximateMemberCount}
                  </Text>{" "}
                  members
                </>
              ),
              !!org.approximatePresenceCount && (
                <>
                  <Text fontWeight="medium" as="span" fontSize="0.9em">
                    {org.approximatePresenceCount}
                  </Text>{" "}
                  online
                </>
              ),
              !!org.website && (
                <a href={org.website} target="_blank" rel="noreferrer">
                  <Icon as={FiLink} mr="0.3em" fontSize="0.7em" />
                  <ExternalLink fontWeight="medium" as="span" fontSize="0.9em">
                    website
                  </ExternalLink>
                </a>
              ),
              !!org.editLink && (
                <a href={org.editLink} target="_blank" rel="noreferrer">
                  <Icon as={FiEdit} mr="0.3em" fontSize="0.7em" />
                  <ExternalLink fontWeight="medium" as="span" fontSize="0.9em">
                    edit
                  </ExternalLink>
                </a>
              ),
            ]).reduce<JSX.Element[]>(
              (jsx, el, index) => (index === 0 ? [el] : jsx.concat([<> / {el}</>])),
              []
            )}
          </Box>

          {org.description && (
            <Text
              fontWeight="light"
              borderLeftColor="gray.500"
              borderLeftStyle="solid"
              borderLeftWidth="2px"
              paddingLeft="1em"
              lineHeight="1.2em"
              marginBottom="1em"
            >
              {org.description}
            </Text>
          )}

          {org.termsLink && !user && (
            <Text fontWeight="light" lineHeight="1.2em" marginBottom="1em" fontSize={15}>
              By connecting your wallet, you are confirming that you have read and agree with the{" "}
              <Link
                href={`/${org.slug}-terms.pdf`}
                target="_blank"
                textDecoration="underline !important"
              >
                {org.name} Terms of Use
              </Link>
              .
            </Text>
          )}
        </Box>

        <Progress
          size="xs"
          isIndeterminate={isLoading}
          mb={5}
          opacity={isLoading ? 1 : 0}
          colorScheme="gray"
          transition={isLoading ? "opacity 1s" : "opacity 0"}
        />

        <Grid
          templateColumns={["repeat(1, 1fr)", "repeat(1, 1fr)", "repeat(2, 1fr)"]}
          gap={6}
          alignItems="center"
          mb={8}
        >
          <GridItem
            colSpan={1}
            mb={1}
            opacity={addWalletIsLoading || isLoading ? "0.7" : undefined}
          >
            <AuthProviders
              user={user}
              org={org}
              walletConnectingMessage={walletConnectingMessage}
              walletSignin={walletSignin}
            />

            {org.forms.length > 0 && (
              <Box flex={1} mt={5}>
                <Divider mb={5} borderColor="gray.500" />
                {org.forms.map((form) => (
                  <Box d="flex" key={form.formId}>
                    <TallyLink
                      formId={form.formId}
                      key={form.formId}
                      orgId={org.id}
                      orgName={org.name}
                      formTitle={form.title}
                      state={form.state}
                    />
                  </Box>
                ))}
              </Box>
            )}

            {org.shouldShowCaptcha && user && org.member && isHuman === false && (
              <Captcha onVerify={onCaptchaTokenChanged} mt={5} />
            )}
            {org.shouldShowEmailVerification && user && (
              <EmailVerificationButton verifiedEmail={user.email} />
            )}
            {org.shouldShowSmsVerification && user && (
              <SmsVerificationButton verifiedSms={user.phoneNumber} />
            )}
            {/* //TODO: dumb for now */}
            {org.shouldShowBrightId && user && user.eth?.[0]?.address && (
              <BrightIDVerificationLink
                userId={user.id}
                contextId={user.eth?.[0]?.address}
                orgId={org.id}
                addressId={user.eth?.[0]?.connectionId}
                refreshMembership={refreshMembership}
              />
            )}
            {/* {org.userIsMember && <Button onClick={leaveOrg}>Leave {org.name}</Button>} */}
            {typeof isHuman === "boolean" &&
              !user &&
              org.shouldShowSignedOutDiscordInvite &&
              !!org.guildId && <SignedOutDiscordInvite orgId={org.id} />}
          </GridItem>
          <GridItem
            colSpan={1}
            alignItems="center"
            justifyContent="center"
            d="flex"
            order={[-1, -1, 1]}
            pt={["1em", "2em"]}
            pl={2}
            mb={8}
          >
            {org.iconURL && (
              <Image
                src={org.iconURL}
                alt={`${org.name} logo`}
                borderRadius="100%"
                fit="contain"
                zIndex="1"
                maxHeight="128px"
                minHeight="64px"
              />
            )}
          </GridItem>
        </Grid>

        {user && org.userIsMember && <Membership org={org} user={user} />}
        <OrgNotionPages org={org} />
        {!!org.roles.length && (
          <Box>
            <HStack mb={1}>
              <Header flex="1">{!!approvedRoles.length && "Your "}Roles</Header>
              {user && (
                <Button
                  size={"sm"}
                  colorScheme="gray"
                  onClick={checkMembership}
                  rightIcon={membershipChecking ? <Loader /> : <FiRefreshCw />}
                  disabled={isLoading}
                >
                  Check
                </Button>
              )}
            </HStack>
            {approvedRoles.map((role) => (
              <RoleButton
                key={role.id}
                role={role}
                toogleUserRolePropagation={toogleUserRolePropagation}
                isOrgMember={org.userIsMember}
                userRolePropagationIsEnabled={userRolePropagationIsEnabled}
              />
            ))}

            {otherRoles.length && approvedRoles.length ? (
              <Header flex="1" mt={6} mb={1}>
                Other Roles
              </Header>
            ) : null}

            {otherRoles.map((role) => (
              <RoleButton
                key={role.id}
                role={role}
                toogleUserRolePropagation={toogleUserRolePropagation}
                isOrgMember={org.userIsMember}
                userRolePropagationIsEnabled={userRolePropagationIsEnabled}
              />
            ))}
          </Box>
        )}
      </Box>
    </Box>
  )
}

const ExternalLink = styled(Text)`
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
`

const Membership = ({ user, org }: { user: CurrentUser; org: CurrentOrg }) => (
  <Stack mb={8} direction={["column", "column"]} spacing={6}>
    {!!org.guildId && (
      <Box d="flex" alignItems="flex-start" fontSize={[12, 13, 15]} mb={4} flex={1}>
        <Box flex="1" d="flex" flexDir="column">
          <Text fontWeight="medium" fontSize="1.5em" opacity="0.8">
            Community
          </Text>

          {org.member && (
            <Text
              as="a"
              aria-label="discord"
              fontWeight="normal"
              ml="0.05em"
              color="gray.600"
              mt={-1}
            >
              <Icon as={SiDiscord} ml={0.2} mr={1} />
              {/* //TODO: dumb for now */}
              {org.member?.nick || user?.discord?.[0]?.username || "discord user"}
            </Text>
          )}
        </Box>

        <ButtonGroup isAttached size="md">
          <OpenDiscordButton orgId={org.id} />
        </ButtonGroup>
      </Box>
    )}
  </Stack>
)
