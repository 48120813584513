import { Button, keyframes, Link, LinkProps } from "@chakra-ui/react"
import styled from "@emotion/styled"
import { MemberCheckStatus } from "db"
import { FiCheck, FiChevronRight, FiLoader, FiX } from "react-icons/fi"

const spin = keyframes({
  "0%": {
    transform: "rotate(0deg)",
  },
  "100%": {
    transform: "rotate(360deg)",
  },
})

export const Loader = styled(FiLoader)`
  animation: ${spin} 4s linear infinite;
`

export const StatusToIcon: { [k in MemberCheckStatus]: { icon: React.FC; message: string } } = {
  APPROVED: { icon: FiCheck, message: "Approved" },
  PENDING: {
    icon: Loader,
    message: "pending...",
  },
  DENIED: { icon: FiX, message: "Unapproved" },
}
export const getStatusIcon = (mcs?: MemberCheckStatus, fallback: boolean = false) =>
  mcs
    ? StatusToIcon[mcs]
    : fallback
    ? { icon: FiChevronRight, message: undefined }
    : { icon: undefined, message: undefined }

export const ConnectionContainerButton = styled(Button)`
  .fade-in {
    transition: opacity 0.3s, width 0.2s;
    opacity: 0;
  }
  .fade-in-07 {
    transition: opacity 0.3s, width 0.2s;
    opacity: 0;
  }
  &:hover,
  &:focus {
    .fade-in {
      opacity: 1;
    }
    .fade-in-07 {
      opacity: 0.7;
    }
  }
  .hide-right-icon {
    overflow-x: hidden;
    width: 0;
  }
  &:hover {
    .hide-right-icon {
      width: 1.1em;
    }
  }

  .hide-action {
    width: 0;
  }
  &:hover {
    .hide-action {
      width: auto;
    }
  }
`

export const NormalLinkIf: React.FC<LinkProps> = ({ children, href, ...rest }) =>
  href ? (
    <Link href={href} {...rest}>
      {children}
    </Link>
  ) : (
    <>{children}</>
  )
