import {
  Box,
  Button,
  Icon,
  Text,
  Tooltip,
  Collapse,
  List,
  Link,
  ListIcon,
  ListItem,
  SpaceProps,
  ThemeTypings,
  As,
  styled,
} from "@chakra-ui/react"
import { useMutation } from "blitz"
import { MemberCheckStatus, TelegramToken } from "db"
import { FC, MouseEventHandler, useState } from "react"
import { IconType } from "react-icons"
import createTelegramOtp from "../mutations/createTelegramOtp"
import { CurrentOrgRole, RequirementWithCheck } from "../queries/getOrg"
import { ConnectionContainerButton, getStatusIcon } from "./status"

const ChecksList = ({
  requirements,
  color,
  pl,
  pt,
  pb,
  roleChecks,
}: {
  requirements: RequirementWithCheck[]
  color: ThemeTypings["colors"]
  pl?: SpaceProps["pl"]
  pt?: SpaceProps["pt"]
  pb?: SpaceProps["pb"]
  roleChecks: CurrentOrgRole[]
}) => (
  <List
    spacing={[1, 2]}
    color={color}
    fontWeight="normal"
    textAlign="left"
    whiteSpace="normal"
    pl={pl}
    pt={pt}
    pb={pb}
  >
    {roleChecks.map((role) => {
      const { icon, message } = getStatusIcon(role.status, true)
      return (
        <ListItem key={role.id}>
          {icon && (
            <Tooltip label={message} aria-label={message}>
              <span>
                <ListIcon as={icon} pb="0.1em" />
              </span>
            </Tooltip>
          )}

          {role.name}
        </ListItem>
      )
    })}
    {requirements.map((req) => {
      const { icon, message } = getStatusIcon(req.check?.status, true)
      const description = req.description
        ? req.description
        : req.name === "connected"
        ? "connected"
        : "unknown"
      return (
        <ListItem key={req.id}>
          {icon && (
            <Tooltip label={message} aria-label={message}>
              <span>
                <ListIcon as={icon} pb="0.1em" />
              </span>
            </Tooltip>
          )}

          {description + " "}

          <Box as="span">
            {req.links.map((link) => {
              if (Array.isArray(link)) {
                return (
                  <Link
                    href={link[1]}
                    onClick={(event) => {
                      event.stopPropagation()
                    }}
                    target="_blank"
                    color="gray.400"
                    key={link[0]}
                    mx={1}
                  >
                    {link[0]} ↗
                  </Link>
                )
              }
              return null
            })}
          </Box>
        </ListItem>
      )
    })}
  </List>
)

const splitStringByEmoji = (string: string) => {
  const emojiRegex = new RegExp(
    `\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff]`,
    "g"
  )

  let lastIndex = 0

  const results = Array.from(string.matchAll(emojiRegex)).flatMap(
    ({ [0]: emoji, index }, arrayIndex, allMatches) => {
      if (typeof index !== "number") return []

      const result = [
        {
          content: emoji,
          type: "emoji",
        },
      ]

      if (index > lastIndex) {
        result.unshift({
          content: string.slice(lastIndex, index),
          type: "text",
        })
      }

      lastIndex = index + emoji!.length

      return result
    }
  )

  if (lastIndex !== string.length) {
    results.push({
      content: string.slice(lastIndex),
      type: "text",
    })
  }

  return results
}

const RoleButtonAction: React.FC<{
  role: CurrentOrgRole
  icon: IconType | FC<{}> | undefined
  message: string | undefined
  joinTelegramGroup: () => void
  shouldPropagate: boolean
  userRolePropagationIsEnabled: boolean
  toogleUserRolePropagation: (roleId: string) => void
  isOrgMember: boolean
}> = ({
  icon,
  message,
  joinTelegramGroup,
  shouldPropagate,
  isOrgMember,
  userRolePropagationIsEnabled,
  toogleUserRolePropagation,
  role,
}) => {
  const { status, telegramGroups, id, isEntryRole } = role
  const joinTelegramGroupHandler: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation()
    joinTelegramGroup()
  }

  const isTelegramRole = !!telegramGroups.length

  const hideLinkText = shouldPropagate ? "Hide" : "Hidden"
  const hideTooltip = shouldPropagate
    ? "Click to hide this role externally, removing it on Discord."
    : "This role will not be shown on Discord. Click to unhide."

  return (
    <Box flexDirection="row" display="flex" alignItems="center">
      {isTelegramRole && (
        <Button disabled={status !== "APPROVED"} onClick={joinTelegramGroupHandler}>
          Join Group
        </Button>
      )}
      {userRolePropagationIsEnabled && isOrgMember && role.discordPropagation && (
        <Tooltip label={hideTooltip} aria-label={hideTooltip}>
          <Text
            fontWeight="normal"
            fontSize={[11, 14, 15]}
            mr={1}
            color="white"
            opacity={0.7}
            className={!shouldPropagate ? undefined : "fade-in-07"}
            onClick={(e) => {
              e.stopPropagation()
              toogleUserRolePropagation(id)
            }}
          >
            {hideLinkText}
          </Text>
        </Tooltip>
      )}

      {icon && (
        <Tooltip label={message} aria-label={message}>
          <Box mt={-0.5}>
            <Icon
              as={icon}
              boxSize="0.9em"
              fontSize={[18, 18, 20]}
              opacity={shouldPropagate ? 1.0 : 0.6}
              data-cy={isEntryRole ? "entry-role-status" : undefined}
              name={status}
            />
          </Box>
        </Tooltip>
      )}
    </Box>
  )
}

export const RoleButton: React.FC<{
  role: CurrentOrgRole
  toogleUserRolePropagation: (roleId: string) => void
  isOrgMember: boolean
  userRolePropagationIsEnabled: boolean
}> = ({ role, toogleUserRolePropagation, isOrgMember, userRolePropagationIsEnabled }) => {
  const { status, requirements, name, color, isEntryRole, telegramGroups, roleChecks } = role

  const alwaysOpen = role.requirements?.length === 1
  const [isOpen, setOpen] = useState(
    alwaysOpen || (isEntryRole && status === MemberCheckStatus.DENIED)
  )
  const { icon, message } = getStatusIcon(status)

  const [createTelegramOtpHandler] = useMutation(createTelegramOtp)

  const joinTelegramGroup = async () => {
    if (status !== "APPROVED") {
      return
    }

    const telegramGroup = telegramGroups?.[0]!

    const telegramToken: TelegramToken = await createTelegramOtpHandler({
      telegramGroupId: telegramGroup.id,
    })

    window.open(
      `https://t.me/${process.env.NEXT_PUBLIC_TELEGRAM_BOT_USERNAME}?start=${telegramToken.token}`,
      "_blank"
    )
  }

  return (
    <ConnectionContainerButton
      colorScheme={"gray"}
      isFullWidth={true}
      size="lg"
      py="0.4em"
      h="auto"
      fontSize={[14, 16, 18]}
      variant="outline"
      borderRadius="4px"
      justifyContent="left"
      px={3}
      onClick={() => setOpen(!isOpen)}
      d="flex"
      flexWrap="wrap"
      flexDirection="row"
      mb={1.5}
    >
      <Box bg={color} boxSize={4} borderRadius="full" as="span" d="inline-block" mr={2}></Box>
      <Text textAlign="left" fontWeight="medium">
        {splitStringByEmoji(name).map(({ content, type }, index) => (
          <Text
            as="span"
            key={`${content}-${index}`}
            {...(type === "emoji"
              ? {
                  display: "inline-block",
                  width: "1.19em",
                }
              : undefined)}
          >
            {content}
          </Text>
        ))}
      </Text>

      <Text
        color="white"
        opacity={0.7}
        fontSize={[12, 14]}
        pl={3}
        fontWeight="normal"
        flex="1"
        textAlign="left"
      >
        {!!requirements?.length
          ? `
            ${requirements?.length} requirement${requirements?.length !== 1 ? "s" : ""}
        `
          : ""}
        {!!requirements?.length && !!roleChecks.length ? "&" : ""}
        {!!roleChecks?.length
          ? `
            ${roleChecks.length} role${roleChecks?.length !== 1 ? "s" : ""}
        `
          : ""}

        {role.holdersCount !== undefined
          ? ` 𐤟 ${role.holdersCount} holder${role.holdersCount === 1 ? "" : "s"}`
          : ""}
      </Text>
      <Box flexBasis="0" display="flex" flexDirection="row" width="100%" alignItems="center">
        {/* <Progress size="xs" isIndeterminate /> // TODO if loading, fill background with this? */}
        <RoleButtonAction
          role={role}
          icon={icon}
          message={message}
          joinTelegramGroup={joinTelegramGroup}
          shouldPropagate={role.shouldPropagate}
          isOrgMember={isOrgMember}
          userRolePropagationIsEnabled={userRolePropagationIsEnabled}
          toogleUserRolePropagation={toogleUserRolePropagation}
        />
      </Box>
      <Box h={0} flexBasis="100%"></Box>
      {requirements?.length || roleChecks?.length ? (
        <Collapse startingHeight="0em" in={alwaysOpen || isOpen}>
          <Box fontSize={[12, 14]} pl={[0.5, 0]} mt={1}>
            <ChecksList
              requirements={requirements || []}
              roleChecks={roleChecks}
              color={"gray.300"}
              pt={2}
              pb={0.5}
            />
          </Box>
        </Collapse>
      ) : null}
    </ConnectionContainerButton>
  )
}
