// user
import { Box, Button, Icon, Image, Text, TextProps, useColorMode } from "@chakra-ui/react"
import { UserContext } from "app/core/layouts/Layout"
import RemoveConnection from "app/users/mutations/removeConnection"
import { useMutation } from "blitz"
import { useContext, useState } from "react"
import { FiX } from "react-icons/fi"
import { ConfirmationModal } from "./ConfirmationModal"
import { useDisconnect } from "wagmi"
import { connectionToMeta } from "./connections"
import { OrgContext } from "./orgPage"
import { Connection } from "app/lib/requirements/types"

export const getSubTheme = (colorMode: "light" | "dark") => {
  const cardBackground = colorMode === "light" ? "gray.300" : "gray.900"
  const grayTextColour = colorMode === "light" ? "gray.600" : "gray.500"

  return { cardBackground, grayTextColour }
}

export const ColumnCard: React.FC<{
  name: string
  description: string
  image: string
  connected: boolean
  shrinkLogo?: boolean
}> = ({ name, description, image, connected, shrinkLogo, children }) => {
  const { colorMode } = useColorMode()
  const { cardBackground, grayTextColour } = getSubTheme(colorMode)

  return (
    <Box bg={cardBackground} display="flex" borderRadius={20} p={6} flexDir="column" flex="1">
      <Box display="flex" flexDir="row" alignItems="center">
        <Image
          src={image}
          alt="wallet logo"
          display="flex"
          mr={3}
          h={shrinkLogo ? 8 : 9}
          ml={0.5}
        />
        <Text fontWeight="semibold" fontSize="2xl" display="flex">
          {name}
        </Text>
        <ConnectedDot connected={connected} />
      </Box>

      <Subtitle color={grayTextColour} mt={3}>
        {description}
      </Subtitle>

      {children}
    </Box>
  )
}

export const Subtitle = (props: TextProps) => (
  <Text {...{ fontSize: 17, fontWeight: "medium", lineHeight: 1.4, ...props }} />
)

export const ConnectedDot = ({ connected }: { connected: boolean }) => (
  <Box
    fontWeight="semibold"
    fontSize={60}
    display="flex"
    bg={connected ? "#58FFA5" : "#FF5858"}
    // lineHeight="inherit"
    ml={2}
    as="span"
    h={2.5}
    w={2.5}
    borderRadius="full"
  ></Box>
)

export const ConnectedAccount = ({
  title,
  address,
  connection,
  connectionId,
}: {
  title?: string
  address?: string
  connection: Connection
  connectionId?: string
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const onClose = () => setIsOpen(false)
  const { user, refetch: refetchUser, nonce } = useContext(UserContext)!
  const { refetch: refetchOrg } = useContext(OrgContext)!
  const [removeConnection, { isLoading }] = useMutation(RemoveConnection)
  const { disconnect } = useDisconnect()

  const [isRemoving, setIsRemoving] = useState(false)

  const remove = async () => {
    setIsRemoving(true)
    await removeConnection({ connection, connectionId: connectionId! })
    if (connection === "eth") {
      disconnect()
    }
    await Promise.all([refetchUser(), refetchOrg()])

    setIsRemoving(false)
  }

  return (
    <Box
      d="flex"
      p={2}
      h={12}
      background="rgba(255,255,255,0.1)"
      alignItems="center"
      borderRadius={6}
      mt={2}
    >
      {/* <Box borderRadius="full" w={7} h={7} backgroundColor="blue"></Box> */}
      {title && (
        <Text fontWeight="semibold" ml={2}>
          {title}
        </Text>
      )}
      {address && (
        <Text
          fontWeight="normal"
          opacity={0.5}
          ml={2}
          flexShrink={5}
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
        >
          {!isLoading && !isRemoving ? address : "Disconnecting..."}
        </Text>
      )}

      <Text flex={1}></Text>

      <Button
        _hover={{ color: "#FF5858", bg: "rgba(255,88,88,0.1)", w: "auto", p: 2 }}
        alignSelf="flex-end"
        color={"whiteAlpha.600"}
        onClick={(e) => {
          setIsOpen(true)
          e.stopPropagation()
        }}
        size="sm"
        w={8}
        isLoading={isLoading || isRemoving}
        p={0}
        css={`
          span {
            display: none;
          }
          &:hover {
            span {
              display: inline;
            }
          }
          svg {
            padding-right: 2px;
          }
        `}
      >
        <Icon as={FiX} fontSize={24} ml={0} /> <span>Disconnect</span>
      </Button>
      <ConfirmationModal
        headerText={`Disconnect ${connectionToMeta[connection].name}`}
        bodyText="Are you sure? You may lose access to some roles."
        isOpen={isOpen}
        isLoading={isLoading}
        onClose={onClose}
        actions={[{ label: "Disconnect", fn: remove }]}
      />
    </Box>
  )
}
