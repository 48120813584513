import { useParam, useQuery } from "blitz"
import { useState, useEffect } from "react"
import GetOrg, { GetOrgT } from "./queries/getOrg"

export const useGetOrg = () => {
  const slug = useParam("slug", "string")
  const [refetchInterval, setRefetchInterval] = useState<number | undefined>()
  const [org, { isFetched, refetch, isFetching }] = useQuery<GetOrgT>(
    GetOrg,
    { slug },
    { refetchInterval, suspense: true, retry: (_, error: any) => error?.statusCode !== 404 }
  )

  useEffect(() => {
    if (org?.isChecking && !refetchInterval) {
      setRefetchInterval(1100)
    } else if (!org?.isChecking && !!refetchInterval) {
      setRefetchInterval(undefined)
    }
  }, [org?.isChecking])

  return { org, isFetched, refetch, isFetching }
}
