import { ethers } from "ethers"
import { z, ZodType } from "zod"

// Alchemy network keys webhook https://docs.alchemy.com/alchemy/enhanced-apis/notify-api#network
export type AlchemyNetworkKey =
  | "ETH_MAINNET"
  | "ETH_GOERLI"
  | "ETH_ROPSTEN"
  | "ETH_RINKEBY"
  | "ETH_KOVAN"
  | "MATIC_MAINNET"
  | "MATIC_MUMBAI"
  | "ARB_MAINNET"
  | "ARB_RINKEBY"
  | "OPT_MAINNET"
  | "OPT_KOVAN"

export type Network =
  | "mainnet"
  | "polygon"
  | "gnosis"
  | "harmony"
  | "arbitrum"
  | "optimism"
  | "bsc"
  | "moonbeam"
  | "avalanche"

export type NetworkMetaData = {
  alchemyKey?: AlchemyNetworkKey
  name: string
  rpc?: () => string
  logo?: string
  gnosisPrefix?: string
  blockExplorer?: {
    name: string
    href: string
    supportsNftView: boolean
  }
  openseaPrefix?: string
  chainId: number
}

const EthNetworks: { [key in Network]: NetworkMetaData } = {
  mainnet: {
    name: "Ethereum",
    alchemyKey: "ETH_MAINNET",
    rpc: () => process.env.ETH_MAIN_RPC!,
    logo: "eth.svg",
    gnosisPrefix: "eth",
    blockExplorer: {
      name: "Etherscan",
      href: "https://etherscan.io/",
      supportsNftView: true,
    },
    openseaPrefix: "ethereum",
    chainId: 1,
  },
  polygon: {
    name: "Polygon",
    alchemyKey: "MATIC_MAINNET",
    rpc: () => process.env.POLYGON_MAIN_RPC!,
    logo: "eth.svg",
    gnosisPrefix: "matic",
    blockExplorer: {
      name: "Polygonscan",
      href: "https://polygonscan.com/",
      supportsNftView: false,
    },
    openseaPrefix: "matic",
    chainId: 137,
  },
  gnosis: {
    name: "gnosis",
    rpc: () => process.env.GNOSIS_MAIN_RPC!,
    logo: "eth.svg",
    gnosisPrefix: "gno",
    chainId: 100,
  },
  harmony: {
    name: "harmony",
    rpc: () => process.env.HARMONY_MAIN_RPC!,
    logo: "eth.svg",
    gnosisPrefix: "one",
    chainId: 1666600000,
  },
  arbitrum: {
    name: "arbitrum",
    alchemyKey: "ARB_MAINNET",
    rpc: () => process.env.ETH_ARBITRUM_RPC!,
    logo: "eth.svg",
    gnosisPrefix: "arb1",
    blockExplorer: {
      name: "Arbiscan",
      href: "https://arbiscan.io/",
      supportsNftView: false,
    },
    chainId: 42161,
  },
  optimism: {
    name: "optimism",
    alchemyKey: "OPT_MAINNET",
    rpc: () => process.env.ETH_OPTIMISM_RPC!,
    logo: "eth.svg",
    gnosisPrefix: "oeth",
    blockExplorer: {
      name: "Optimism",
      href: "https://optimistic.etherscan.io/",
      supportsNftView: false,
    },
    openseaPrefix: "optimism",
    chainId: 10,
  },
  bsc: {
    name: "Binance Smart Chain",
    rpc: () => process.env.ETH_BSC_RPC!,
    logo: "bsc.svg",
    gnosisPrefix: "bnb",
    blockExplorer: {
      name: "BscScan",
      href: "https://bscscan.com/",
      supportsNftView: false,
    },
    chainId: 56,
  },
  moonbeam: {
    name: "Moonbeam",
    rpc: () => process.env.MOONBEAM_MAIN_RPC!,
    logo: "eth.svg",
    gnosisPrefix: "glmr",
    blockExplorer: {
      name: "Moonbeam",
      href: "https://moonscan.io/",
      supportsNftView: false,
    },
    chainId: 1284,
  },
  avalanche: {
    name: "Avalanche",
    gnosisPrefix: "avax",
    rpc: () => process.env.AVALANCHE_MAIN_RPC!,
    logo: "eth.svg",
    chainId: 43114,
    blockExplorer: {
      name: "Snowtrace",
      href: "https://snowtrace.io/",
      supportsNftView: false,
    },
  },
}

export const getNetworkMetadata = (network: Network) => {
  const metadata = EthNetworks?.[network]
  if (!metadata) throw new Error(`no network metadata for ${network}`)
  return metadata
}

export const getNetworkMetadataByChainId = (chainId: number): [Network, NetworkMetaData] => {
  const name = Object.keys(EthNetworks).find((n) => EthNetworks[n].chainId === chainId) as Network
  if (!name) throw new Error(`no network metadata for chainId ${chainId}`)
  return [name, EthNetworks[name]]
}

export const EthNetworkKeys = Object.keys(EthNetworks)

export function numericEnum<TValues extends readonly number[]>(values: TValues) {
  return z.number().superRefine((val, ctx) => {
    if (!values.includes(val)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Invalid enum value. Expected ${values.join(" | ")}, received ${val}`,
      })
    }
  }) as ZodType<TValues[number]>
}

export const chainIdZod = numericEnum(Object.values(EthNetworks).map((n) => n.chainId))

export const getProvider = (network: Network) => {
  const metadata = getNetworkMetadata(network)
  const url = metadata?.rpc?.()
  if (!url) throw new Error(`no rpc in env var and/or network: ${network} not configured`)

  return new ethers.providers.StaticJsonRpcProvider({ url })
}

export const getNetworkKeyByAlchemyKey: (alchemyKey: AlchemyNetworkKey) => Network = (
  alchemyKey
) => {
  switch (alchemyKey) {
    case "ETH_MAINNET":
      return "mainnet"
    case "MATIC_MAINNET":
      return "polygon"
    case "ARB_MAINNET":
      return "arbitrum"
    case "OPT_MAINNET":
      return "optimism"
    default:
      throw new Error(`unknown alchemy network key: ${alchemyKey}`)
  }
}

export const getNetworkInfo = (networks: Network[]) => EthNetworks[networks[0] || "mainnet"]!
