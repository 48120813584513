import { enhancePrisma } from "blitz"
import { PrismaClient } from "@prisma/client"

require("../integrations/loggedHttps")

const EnhancedPrisma = enhancePrisma(PrismaClient)

const prisma = new EnhancedPrisma({
  log: ["info", `warn`, `error`],
})

// @ts-ignore
if (!prisma._alreadySetup) {
  // console.log("setting up")
  // prisma.$on<"query">("query", (e) => {
  //   console.log(`dur: ${e.duration}ms params: ${e.params}`)
  // })

  // @ts-ignore
  prisma._alreadySetup = true
}

export * from "@prisma/client"
export default prisma
