import { Button, ButtonGroup, Icon, useColorMode } from "@chakra-ui/react"
import { ConnectButton } from "@rainbow-me/rainbowkit"
import useWalletSignin from "app/core/hooks/useWalletSignin"
import { getNetworkInfo, Network } from "app/lib/provider"
import { CurrentUser } from "app/users/queries/getCurrentUser"
import { useRouter } from "blitz"
import { FiPlus, FiX } from "react-icons/fi"
import { useDisconnect } from "wagmi"
import { ColumnCard, ConnectedAccount, getSubTheme } from "./ensComponents"

const shortAddress = (address: string) => `${address.slice(0, 6)}...${address.slice(39, 42)}`
export const hexlify = (message: string) => "0x" + Buffer.from(message, "utf8").toString("hex")

export const WalletLogin = ({
  wallets,
  networks,
}: {
  wallets?: CurrentUser["eth"]
  networks: Network[]
}) => {
  const router = useRouter()
  const { colorMode } = useColorMode()
  const { cardBackground, grayTextColour } = getSubTheme(colorMode)

  const { walletSignin, waitingForSign, addWalletIsLoading } = useWalletSignin()

  const walletConnectingMessage = waitingForSign
    ? "Check for sign..."
    : addWalletIsLoading
    ? "Connecting..."
    : null

  const wallet = wallets?.[0]

  const { disconnect } = useDisconnect()

  const networkInfo = getNetworkInfo(networks)
  return (
    <ColumnCard
      connected={!!wallet}
      description={`Connect a wallet which supports ${networkInfo.name} to verify your ownership of tokens & NFTs.`}
      name={networkInfo.name}
      image={"/" + (networkInfo.logo || "eth.svg")}
      //@ts-ignore
      onMouseLeave={() => {
        console.log("Event:onMouseLeave")
      }}
      shrinkLogo={networkInfo.logo === "bsc.svg"}
    >
      {/* <Buttons /> */}
      {/* //TODO: this is dumb for now */}
      {wallet ? (
        <ConnectedAccount
          title={wallet.ensAddress ?? undefined}
          address={shortAddress(wallet.address)}
          connection="eth"
          connectionId={wallet.connectionId}
        />
      ) : walletConnectingMessage ? (
        <Button mt={4} disabled={true} background="black" color={grayTextColour}>
          {walletConnectingMessage}
        </Button>
      ) : (
        <ConnectButton.Custom>
          {({ account, openConnectModal }) =>
            !account?.address ? (
              <Button
                mt={4}
                size="md"
                onClick={() => {
                  delete router.query.authError
                  openConnectModal()
                }}
              >
                Connect Wallet
              </Button>
            ) : (
              <ButtonGroup isAttached={true} mt={1}>
                <Button
                  flex="1"
                  borderRightColor={cardBackground}
                  borderRightWidth={2}
                  onClick={() => {
                    walletSignin()
                  }}
                >
                  <Icon as={FiPlus} mr={1} /> Verify {account.address.slice(0, 7) + "..."}
                </Button>
                <Button
                  borderLeftColor={cardBackground}
                  borderLeftWidth={2}
                  onClick={() => {
                    disconnect()
                  }}
                >
                  <Icon as={FiX} mr={1} /> Reset
                </Button>
              </ButtonGroup>
            )
          }
        </ConnectButton.Custom>
      )}
    </ColumnCard>
  )
}
