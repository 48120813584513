import { Box, Image, Text, Button } from "@chakra-ui/react"
import { getLoginLink } from "app/lib/urlUtils"
import { useRouter } from "blitz"
import { CurrentOrg } from "../queries/getOrg"

export const ENSHomePage = ({ org }: { org: CurrentOrg }) => {
  const router = useRouter()

  const handleClick = () => {
    const path = router.asPath.split("?")[0]
    window.location.href = getLoginLink("discord", org.id, path)
  }

  return (
    <Box flex="1" d="flex" flexDir="column" justifyContent="center" alignItems="center" p={"0"}>
      {/* <Box d="flex" justifyContent="center"> */}
      <Image
        src={org.iconURL || undefined}
        alt="eth logo"
        display="flex"
        p={0}
        mt={1}
        borderRadius="100%"
        fit="contain"
        zIndex="1"
        maxHeight={400}
      />
      <Text fontWeight="semibold" fontSize={50} mt={7}>
        {org.name}
      </Text>
      <Text fontSize={17} color="whiteAlpha.700" textAlign="center" mt={3}>
        To join the conversation on Discord,
        <br />
        and manage your roles, connect your account.
      </Text>
      <Button bg="discord.400" mt={10} size="lg" borderRadius="full" onClick={handleClick}>
        <Image
          src="/discord-icon-black.svg"
          alt="discord logo"
          h={5}
          css={{ filter: "invert(100%)" }}
          mr={3}
        />{" "}
        Login with Discord
      </Button>

      {/* </Box> */}
    </Box>
  )
}
