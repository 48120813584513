import { Box, Button, Flex, ListItem, Text, UnorderedList } from "@chakra-ui/react"
import { NotionMetadata } from "app/lib/notionUtils"
import { getNotionPageUrl } from "app/lib/urlUtils"
import { CurrentOrg } from "../queries/getOrg"

export const OrgNotionPages = ({ org }: { org: CurrentOrg }) => {
  const { notionPages, slug } = org
  if (!notionPages.length) return null
  return (
    <Box mb="32px">
      <Text fontWeight="medium" fontSize="1.5em" opacity="0.8">
        Notion pages
      </Text>
      <UnorderedList>
        {notionPages.map((np) => {
          const cachedData = np.metadata as NotionMetadata
          const title = cachedData?.title || np.notionPageId
          return (
            <ListItem key={np.id} color="gray.300">
              <Flex justifyContent="space-between" alignItems="center">
                <Box>{title}</Box>
                <Button
                  variant="outline"
                  as="a"
                  target="_blank"
                  href={getNotionPageUrl(slug, np.id)}
                >
                  Access page
                </Button>
              </Flex>
            </ListItem>
          )
        })}
      </UnorderedList>
    </Box>
  )
}
