import {
  Box,
  Button,
  Text,
  Icon,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
} from "@chakra-ui/react"
import { useMutation } from "blitz"
import { useContext, useEffect, useState } from "react"

import { useRefreshMembership } from "app/users/hooks"
import CreateFormSubmission from "../mutations/createFormSubmission"
import { CurrentOrgForm } from "../queries/getOrg"
import { OrgContext } from "./orgPage"
import { ConnectionContainerButton } from "./status"
import { connectionToMeta } from "./connections"
import { FiAlignJustify, FiArrowUpRight } from "react-icons/fi"
import { SpinnerIcon } from "@chakra-ui/icons"

const DELAY_BEFORE_CLOSE = 2000

export const TallyLink: React.FC<{
  formId: string
  formTitle?: string
  orgId: string
  orgName: string
  state?: CurrentOrgForm["state"]
}> = ({ formId, formTitle, orgId, orgName, state }) => {
  const [isFormOpen, setIsFormOpen] = useState(false)
  const [nobleFormSubmissionId, setNobleFormSubmissionId] = useState<string | null>(null)
  const [needResubmit, setNeedResubmit] = useState(false)
  const [createFormSubmission, { isLoading }] = useMutation(CreateFormSubmission)
  const { refetch: refetchOrg } = useContext(OrgContext)!

  useEffect(() => {
    const eventListener = (e) => {
      try {
        const data = JSON.parse(e.data)

        if (data.event === "Tally.FormSubmitted") {
          setTimeout(() => {
            setIsFormOpen(false)
            setNobleFormSubmissionId(null)
            refetchOrg()
          }, DELAY_BEFORE_CLOSE)
        }
      } catch {}
    }

    if (isFormOpen) {
      window.addEventListener("message", eventListener)

      return () => {
        window.removeEventListener("message", eventListener)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFormOpen])

  return (
    <>
      <ConnectionContainerButton
        href={
          nobleFormSubmissionId && state === "open"
            ? `https://tally.so/r/${formId}?nobleFormSubmissionId=${nobleFormSubmissionId}`
            : "#"
        }
        onClick={async (evt) => {
          evt.preventDefault()
          evt.stopPropagation()
          if (state !== "open") return

          if (!nobleFormSubmissionId) {
            const { id } = await createFormSubmission({ formId })
            setNobleFormSubmissionId(id)
          }
          setNeedResubmit(false)
          setIsFormOpen(true)
        }}
        onContextMenu={async (evt) => {
          evt.preventDefault()
          evt.stopPropagation()
          if (state !== "open") return

          if (!nobleFormSubmissionId) {
            const { id } = await createFormSubmission({ formId })
            setNobleFormSubmissionId(id)
            setNeedResubmit(true)
          } else {
            setNeedResubmit(false)
          }
        }}
        target="_blank"
        // key={formId}
        width="full"
        disabled={state !== "open"}
        leftIcon={<Icon as={FiAlignJustify} />}
        colorScheme={"green"}
        isFullWidth={true}
        size="lg"
        py="0.4em"
        h="auto"
        fontSize={["1.2em", "1.4em", "1.6em"]}
        variant="outline"
        borderRadius="4px"
        justifyContent={true ? "left" : "center"}
        bg={true ? connectionToMeta.twitter.color + ".50" : undefined}
        px={3}
        d="flex"
        flexWrap="wrap"
        flexDirection="row"
        as="a"
        cursor="pointer"
      >
        <Text flex="1" textAlign="left" fontWeight="normal">
          {formTitle || "Open Form"}
        </Text>

        <Text flex="0" textAlign="left" fontSize={16} fontWeight="normal" pr="0.5em">
          {state === "submitted" ? "Submitted" : needResubmit ? "Click Again" : "Open Form"}
          {state === "open" ? (
            isLoading ? (
              <Spinner ml={2} boxSize={4} />
            ) : (
              <Icon as={FiArrowUpRight} />
            )
          ) : null}
        </Text>
      </ConnectionContainerButton>

      <Modal
        isOpen={isFormOpen && !!nobleFormSubmissionId}
        onClose={() => setIsFormOpen(false)}
        size="4xl"
      >
        <ModalOverlay backdropFilter="blur(4px)" />
        <ModalContent m={[0, 12]} height={["100vh", "86vh"]}>
          <ModalHeader bg="gray.900" color="gray.200" fontSize={14}>
            {`${orgName}: ${formTitle}`}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody backgroundColor="white" display="flex" py={0} px={0}>
            <Box
              as="iframe"
              frameBorder="0"
              height={"100%"}
              marginHeight={0}
              marginWidth={0}
              src={`https://tally.so/embed/${formId}?nobleFormSubmissionId=${nobleFormSubmissionId}`}
              title="Tally form"
              width={"100%"}
              px={0}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
