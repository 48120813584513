export function NiftyGatewayLogo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      fill="none"
      className="jss4 jss9"
      data-testid="navbar-logo-light"
      viewBox="0 0 228 233"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M139.265 13.789H88.247c-21.19 0-35.695.029-46.578 1.492-10.487 1.41-15.523 3.92-18.995 7.393-3.473 3.472-5.983 8.508-7.393 18.995-1.463 10.883-1.492 25.388-1.492 46.578v55.859c0 21.19.029 35.695 1.492 46.578 1.41 10.486 3.92 15.523 7.393 18.995 3.472 3.473 8.508 5.983 18.995 7.393 10.883 1.463 25.388 1.492 46.578 1.492h51.018c21.19 0 35.695-.029 46.578-1.492 10.487-1.41 15.523-3.92 18.996-7.393 3.472-3.472 5.982-8.509 7.392-18.995 1.463-10.883 1.493-25.388 1.493-46.578V88.247c0-21.19-.03-35.695-1.493-46.578-1.41-10.487-3.92-15.523-7.392-18.995-3.473-3.473-8.509-5.983-18.996-7.393-10.883-1.463-25.388-1.492-46.578-1.492zm-126.341-.865C0 25.846 0 46.647 0 88.246v55.859c0 41.6 0 62.4 12.924 75.323 12.923 12.924 33.723 12.924 75.323 12.924h51.018c41.6 0 62.4 0 75.324-12.924 12.923-12.923 12.923-33.723 12.923-75.323V88.247c0-41.6 0-62.4-12.923-75.323C201.665 0 180.865 0 139.265 0H88.247c-41.6 0-62.4 0-75.323 12.924z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        d="M140.053 60.876v81.913l-52.67-81.913H64.12v116.307h21.102v-82.91l53.667 82.91h22.264V60.876h-21.101z"
      ></path>
    </svg>
  )
}
