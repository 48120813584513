import { Button } from "@chakra-ui/react"
import { getLoginLink } from "app/lib/urlUtils"
import { CurrentUser } from "app/users/queries/getCurrentUser"
import { useRouter } from "blitz"
import React, { Dispatch, SetStateAction, useState } from "react"
import { ConnectionState } from "types"
import { ColumnCard, ConnectedAccount } from "./ensComponents"

export const TwitterCard = ({
  twitter,
  orgId,
}: {
  twitter?: CurrentUser["twitter"][0]
  orgId: string
}) => {
  const router = useRouter()
  const [loading, setLoading] = useState(false)
  const connectTwitter = () => {
    setLoading(true)
    const path = router.asPath.split("?")[0]
    window.location.href = getLoginLink("twitter", orgId, path)
  }

  return (
    <ColumnCard
      connected={!!twitter}
      description="Link a Twitter account to verify that you are in fact a real account, and not a bot."
      name="Twitter"
      image="/twitter.svg"
    >
      {twitter ? (
        <ConnectedAccount
          title={twitter.nick}
          connection="twitter"
          connectionId={twitter.connectionId}
        />
      ) : (
        <Button mt={4} onClick={connectTwitter} isLoading={loading}>
          Connect Twitter
        </Button>
      )}
    </ColumnCard>
  )
}
