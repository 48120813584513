import { ThemeTypings, Box, Button, useToast } from "@chakra-ui/react"
import RemoveConnection from "app/users/mutations/removeConnection"

import { UserContext } from "app/core/layouts/Layout"

import { useMutation } from "blitz"
import { useState, useRef, useContext } from "react"
import { FiX } from "react-icons/fi"
import { useDisconnect } from "wagmi"
import { connectionToMeta } from "."
import { OrgContext } from "../orgPage"
import { ServerError } from "app/errors/ServerError"
import { ConfirmationModal } from "../ConfirmationModal"
import { Connection } from "app/lib/requirements/types"

export const ConnectionOptions = ({
  connectionId,
  connection,
  color,
  overrideName,
}: {
  connectionId?: string
  connection: Connection
  color: ThemeTypings["colorSchemes"]
  overrideName?: string
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const onClose = () => setIsOpen(false)

  const [removeConnection, { isLoading }] = useMutation(RemoveConnection)
  const { refetch: refetchUser } = useContext(UserContext)!
  const { refetch: refetchOrg } = useContext(OrgContext)!

  const { name } = connectionToMeta[connection]

  const { disconnect } = useDisconnect()

  const toast = useToast()

  const removeAccount = async () => {
    if (connection === "eth") {
      disconnect()
    }

    try {
      await removeConnection({ connectionId: connectionId!, connection })
    } catch (error) {
      if (error instanceof ServerError) {
        toast({
          status: "error",
          title: `Could not disconnect!`,
          // description: "Logout and sign-in with it to remove the claim.",
          duration: 6000,
          isClosable: true,
        })
      }
    }

    refetchUser()
    refetchOrg()

    setIsOpen(false)
  }

  return (
    <Box width="full" pl={[1, 1, 1.5]}>
      <Button
        color={color}
        variant="link"
        leftIcon={<FiX />}
        _hover={{ color: "red" }}
        fontSize="inherit"
        fontWeight="normal"
        onClick={(e) => {
          e.stopPropagation()
          setIsOpen(true)
        }}
      >
        Disconnect
      </Button>

      <ConfirmationModal
        headerText={`Disconnect ${overrideName ?? name}`}
        bodyText="Are you sure? You may lose access to some roles."
        isOpen={isOpen}
        isLoading={isLoading}
        onClose={onClose}
        actions={[{ label: "Disconnect", fn: removeAccount }]}
      />
    </Box>
  )
}
