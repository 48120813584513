import { useEffect, useRef, useState } from "react"
import HCaptcha from "@hcaptcha/react-hcaptcha"
import { Box, BoxProps } from "@chakra-ui/react"

const siteKey = process.env.BLITZ_PUBLIC_HCAPTCHA_SITEKEY || ""

const Captcha: React.FC<
  {
    onVerify: (token: string) => void
  } & BoxProps
> = ({ onVerify, ...rest }) => {
  const captchaRef = useRef(null)

  return (
    <Box {...rest}>
      {/* @ts-ignore */}
      <HCaptcha sitekey={siteKey} theme={"dark"} onVerify={onVerify} ref={captchaRef} />
    </Box>
  )
}

export default Captcha
