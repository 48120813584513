import SuperJson from "superjson"
import { AuthorizationError } from "blitz"

export class WalletAlreadyOwned extends AuthorizationError {
  name = "WalletAlreadyOwned"
  constructor() {
    super()
  }
}

SuperJson.registerClass(WalletAlreadyOwned, { identifier: "WalletAlreadyOwned" })
