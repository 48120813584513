import { useToast } from "@chakra-ui/react"
import { UserContext } from "app/core/layouts/Layout"
import { getLoginLink } from "app/lib/urlUtils"
import OrgLogin from "app/org/components/login"
import VUI from "app/org/components/vui"
import OrgLoginENS from "app/org/components/orgLoginENS"
import { CurrentOrg } from "app/org/queries/getOrg"
import { ErrorComponent, Head, useMutation, useQuery, useRouter } from "blitz"
import { createContext, useContext, useEffect, useRef } from "react"
import { useGetOrg } from "../hooks"
import OtpLoginHandler from "../mutations/otpLoginHandler"
import { ENSHomePage } from "./ensHomepage"

type CurrentOrgT = ReturnType<typeof useQuery>
type OrgContextT = { org?: CurrentOrg; refetch: CurrentOrgT[1]["refetch"] } | null
export const OrgContext = createContext<OrgContextT>(null)

const OrgPage = () => {
  const { org, refetch, isFetched, isFetching } = useGetOrg()
  const { user } = useContext(UserContext)!
  // const otpHasRun = useRef(false)

  // const [otpLoginHandler, { isLoading: isLoggingIn }] = useMutation(OtpLoginHandler)
  // const router = useRouter()
  // const toast = useToast()

  // useEffect(() => {
  //   async function otpLogin() {
  //     otpHasRun.current = true
  //     const loginToken = router.query.loginToken as string
  //     await router.replace(window.location.pathname)
  //     const response = await otpLoginHandler({ loginToken })
  //     if (!response.success) {
  //       toast({
  //         status: "error",
  //         title: response.errorMsg,
  //         duration: 6000,
  //         isClosable: true,
  //       })
  //     }
  //   }
  //   if (!otpHasRun.current && router.query.loginToken && !isLoggingIn) {
  //     otpLogin()
  //   }
  // }, [refetch, otpLoginHandler, toast, router, isLoggingIn])

  if (!isFetched) return null
  if (!org) {
    return <ErrorComponent statusCode={404} title="Org not found" />
  }

  const pageTitle = `${org.name} | Noble`
  const pageDescription = `Login to ${org.name} using Noble.`

  const pageContents = () => {
    if (org.eui) {
      if (user) {
        return <OrgLoginENS org={org} refetch={refetch} parentIsLoading={isFetching} />
      } else {
        return <ENSHomePage org={org} />
      }
    } else if (org.vui) {
      return <VUI org={org} refetch={refetch} parentIsLoading={isFetching} />
    } else {
      return <OrgLogin org={org} refetch={refetch} parentIsLoading={isFetching} />
    }
  }

  return (
    <OrgContext.Provider value={{ org: org || undefined, refetch }}>
      <Head>
        <title>{pageTitle}</title>
        {org.ogImage && <meta property="og:image" content={org.ogImage} key="ogImage" />}
        <meta property="og:title" content={pageTitle} key="title" />
        <meta property="og:description" content={pageDescription} key="description" />
        <link rel="icon" type="image/png" href={org.favicon || undefined} />
      </Head>

      {pageContents()}
    </OrgContext.Provider>
  )
}

export default OrgPage
