import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
} from "@chakra-ui/react"
import { MutableRefObject, useRef } from "react"

export const ConfirmationModal: React.FC<{
  isOpen: boolean
  onClose: () => void
  isLoading?: boolean
  actions: {
    label: string
    fn: () => any
    color?: string
  }[]
  headerText: string
  bodyText: string
}> = ({ headerText, bodyText, isOpen, onClose, isLoading, actions }) => {
  const cancelRef = useRef(null)

  return (
    <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {headerText}
          </AlertDialogHeader>
          <AlertDialogBody>{bodyText}</AlertDialogBody>
          <AlertDialogFooter>
            {actions.map((action, key) => {
              return (
                <Button
                  key={key}
                  colorScheme={action.color}
                  onClick={action.fn}
                  mr={3}
                  isLoading={isLoading}
                >
                  {action.label}
                </Button>
              )
            })}
            <Button ref={cancelRef} onClick={onClose} isDisabled={isLoading}>
              Cancel
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
}
