import { Box, Button, Image, Text, useColorMode, useToast } from "@chakra-ui/react"
import { useMutation } from "blitz"
import React, { MouseEventHandler, useState } from "react"
import { SiDiscord } from "react-icons/si"
import CreateInviteLink, { InviteLink } from "../mutations/createInviteLink"
import { ConnectedDot, getSubTheme, Subtitle } from "./ensComponents"

const shouldUseLink = (invite: InviteLink | undefined) => {
  if (!invite) return false
  const limit = new Date(new Date().getTime() + 30 * 1000)
  return invite.expiresAt > limit
}

export const OpenDiscordButton = ({
  orgId,
  text,
}: {
  orgId: string
  ens?: boolean
  text?: string
}) => {
  const toast = useToast()
  const [createInviteLink, { isLoading, isError, data }] = useMutation(CreateInviteLink)
  const [tapAgain, setTapAgain] = useState(false)

  const inviteLink = data as InviteLink

  const handleOpenDiscord: MouseEventHandler<HTMLButtonElement> = async (e) => {
    if (data && "error" in data) {
      toast({
        status: "error",
        title: `Unknown error!`,
        duration: 6000,
        isClosable: true,
      })
      return
    }
    if (shouldUseLink(data)) {
      return
    }

    e.preventDefault()

    const result = await createInviteLink({ orgId })

    if (!result || typeof window === "undefined" || !("url" in result)) return
    const newWindow: Window | null = window.open(result.url, "_blank")
    if (newWindow) {
      newWindow.focus()
    } else {
      setTapAgain(true)
    }
  }

  const validLink = data && !("error" in data) && shouldUseLink(data)

  return (
    <Button
      as={validLink ? "a" : undefined}
      onClick={handleOpenDiscord}
      href={validLink ? inviteLink?.url : undefined}
      flex={[1, "auto"]}
      target="_blank"
      isLoading={isLoading}
      colorScheme="discord"
      variant="outline"
      width={text && "full"}
      leftIcon={<SiDiscord />}
    >
      <Text color="white" opacity="0.8">
        {isError ? "Error" : tapAgain && validLink ? "Tap again 📲" : text || "Join Discord"}
      </Text>
    </Button>
  )
}

export const DiscordCard = ({ connected, orgId }: { connected: boolean; orgId: string }) => {
  const { colorMode } = useColorMode()
  const { cardBackground, grayTextColour } = getSubTheme(colorMode)

  return (
    <Box
      bg={cardBackground}
      display="flex"
      borderRadius={20}
      p={8}
      flexDir={["column", "row"]}
      flex="1"
      mb={8}
    >
      <Box d="flex" flex="1">
        <Image src="/discord-ens.svg" alt="discord logo" mr={4} p={0} h={10} mt={1} />
        <Box flex="1">
          <Box display="flex" flexDir="row" alignItems="center">
            <Text fontWeight="semibold" fontSize="2xl" display="flex" lineHeight={7}>
              Discord
            </Text>
            <ConnectedDot connected={connected} />
          </Box>
          <Subtitle color={grayTextColour}>Discuss all things with the community.</Subtitle>
        </Box>
      </Box>
      <Box alignItems="center" d="flex" pt={[4, 0]}>
        <OpenDiscordButton orgId={orgId} />
      </Box>
    </Box>
  )
}
