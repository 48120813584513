// user
import {
  Box,
  Button,
  HStack,
  Image,
  Stack,
  Text,
  TextProps,
  useColorMode,
  VStack,
} from "@chakra-ui/react"
import { UserHeader } from "app/core/components/UserHeader"
import { UserContext } from "app/core/layouts/Layout"
import { useRefreshMembership } from "app/users/hooks"
import { useQuery } from "blitz"
import React, { useContext, useMemo, useState } from "react"
import { FiRefreshCw } from "react-icons/fi"
import { ConnectionState, ConnectionStatesMap } from "types"
import { CurrentOrg } from "../queries/getOrg"
import { DiscordCard } from "./DiscordCard"
import { getSubTheme, Subtitle } from "./ensComponents"
import { WalletLogin } from "./ensWalletLogin"
import { RoleRow } from "./roleRow"
import { Loader } from "./status"
import { TwitterCard } from "./TwitterCard"

export const Header = (props: TextProps) => (
  <Text pr={2} fontSize={25} fontWeight="semibold" mb={1} {...props} />
)

export default function OrgLogin({
  org,
  refetch: refetchOrg,
  parentIsLoading,
}: {
  org: CurrentOrg
  parentIsLoading: boolean
  refetch: ReturnType<typeof useQuery>[1]["refetch"]
}) {
  const { colorMode } = useColorMode()
  const { grayTextColour } = getSubTheme(colorMode)

  const { user } = useContext(UserContext)!

  const [refreshMembership, { isLoading: refreshMembershipLoading }] = useRefreshMembership()

  const membershipChecking = org.isChecking
  const isLoading = refreshMembershipLoading || membershipChecking || parentIsLoading

  const checkMembership = async () => {
    try {
      await refreshMembership({ orgId: org.id })
      await refetchOrg()
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Box flex="1" d="flex" flexDir="column" justifyContent="center" p={"0"}>
      <UserHeader user={user} isLoading={parentIsLoading} />

      <Box>
        <Box d="flex" mb={7} alignItems="center">
          <Image
            src={org.iconURL || undefined}
            alt="eth logo"
            display="flex"
            p={0}
            h={24}
            borderRadius="100%"
            fit="contain"
            zIndex="1"
            height={[16, 20, 24]}
          />
          <Text fontWeight="semibold" fontSize={["3xl", "4xl", "5xl"]} ml={4}>
            {org.name}
          </Text>
        </Box>

        <Box mb={6}>
          <Text fontWeight="semibold" fontSize={25} lineHeight={8}>
            Verifications
          </Text>
          <Text fontWeight="regular" fontSize={18} color={grayTextColour}>
            Link your accounts accounts to verify your identity.
          </Text>
        </Box>

        <DiscordCard connected={!!user?.discord} orgId={org.id} />

        <Stack direction={["column", "column", "row"]} align="stretch" spacing={8}>
          <WalletLogin wallets={user?.eth} networks={org.ethNetworks} />
          <TwitterCard twitter={user?.twitter?.[0]} orgId={org.id} />
        </Stack>

        {org.roles.length > 0 && (
          <Box mt={8}>
            <HStack mb={4}>
              <Box flex="1">
                <Header flex="1">Roles</Header>
                <Subtitle color={grayTextColour}>Granted based on accounts connected.</Subtitle>
              </Box>

              {user && (
                <Button
                  size="md"
                  colorScheme="gray"
                  onClick={checkMembership}
                  leftIcon={membershipChecking ? <Loader /> : <FiRefreshCw />}
                  disabled={isLoading}
                >
                  Check again
                </Button>
              )}
            </HStack>
            <VStack spacing={4}>
              {org.roles.map((role) => (
                <RoleRow key={role.id} role={role} />
              ))}
            </VStack>
          </Box>
        )}
      </Box>
    </Box>
  )
}
