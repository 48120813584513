// copied directly from
// https://github.com/rainbow-me/rainbowkit/blob/b2aabbea113e1ba47424750d58e81453de77f6d5/packages/rainbowkit/src/utils/isMobile.ts

export function isAndroid(): boolean {
  return typeof navigator !== "undefined" && /android/i.test(navigator.userAgent)
}

export function isSmallIOS(): boolean {
  return typeof navigator !== "undefined" && /iPhone|iPod/.test(navigator.userAgent)
}

export function isLargeIOS(): boolean {
  return typeof navigator !== "undefined" && /iPad/.test(navigator.userAgent)
}

export function isIOS(): boolean {
  return isSmallIOS() || isLargeIOS()
}

export function isMobile(): boolean {
  return isAndroid() || isSmallIOS()
}
