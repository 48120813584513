import { Box, Button, ButtonProps, Collapse, Icon, Text, ThemeTypings } from "@chakra-ui/react"
import { MemberCheckStatus } from "@prisma/client"
import { ConnectButton } from "@rainbow-me/rainbowkit"
import { CurrentOrgConnection } from "app/org/queries/getOrg"
import { Profile } from "app/users/connections/types"
import { CurrentUser } from "app/users/queries/getCurrentUser"
import { AuthConnection } from "app/users/utils"
import { useState } from "react"
import { useDisconnect } from "wagmi"
import { connectionToMeta } from "."
import { ConnectionContainerButton, NormalLinkIf } from "../status"
import { ConnectionOptions } from "./ConnectionOptions"
import { TelegramLogin } from "./TelegramLogin"

import { FiPlus, FiX } from "react-icons/fi"

type ProviderButtonProps = {
  user?: CurrentUser
  login: () => void
  connectionName: AuthConnection
  connection?: CurrentOrgConnection
  status?: MemberCheckStatus
  loading?: boolean
  walletSignin: () => void
  walletName?: string
  overrideName?: string
  noWalletDisconnect?: boolean
} & ButtonProps

export const ProviderButton: React.FC<ProviderButtonProps> = ({
  user,
  connection,
  connectionName,
  login,
  children,
  status,
  loading,
  walletName,
  walletSignin,
  overrideName,
  noWalletDisconnect,
  ...rest
}) => {
  const [open, setOpen] = useState(status === MemberCheckStatus.DENIED)
  const { color, icon: ConnectionIcon, name, url } = connectionToMeta[connectionName]
  const profile: Profile | undefined = user && user[connectionName] && user[connectionName][0] //TODO: this is dumb for now

  const isEth = connectionName === "eth"

  const { disconnect } = useDisconnect()

  if (color === ("nifty" as ThemeTypings["colorSchemes"])) {
    rest = {
      ...rest,
      ...{
        bgGradient: "linear(to-tl, nifty.50, nifty.100, nifty.800, nifty.900)",
        _hover: {
          bgGradient: "linear(to-br, nifty.50, nifty.100, nifty.800, nifty.900)",
        },
        color: profile ? "white" : undefined,
      },
    }
  }

  const isTelegram = connectionName === "telegram"

  if (isTelegram && !profile) {
    return <TelegramLogin dataOnauth={login} />
  }

  if (isEth && !profile) {
    return (
      <ConnectButton.Custom>
        {({ account, openConnectModal }) => (
          <>
            <ConnectionContainerButton
              leftIcon={walletName ? undefined : <ConnectionIcon />}
              colorScheme={color}
              isFullWidth={true}
              size="lg"
              py="0.4em"
              h="auto"
              fontSize={["1.2em", "1.4em", "1.6em"]}
              variant={profile ? "outline" : "solid"}
              borderRadius="4px"
              justifyContent={profile ? "left" : "center"}
              bg={profile ? color + ".50" : undefined}
              px={3}
              borderColor={connectionName === "eth" ? "gray.500" : undefined}
              disabled={!!account?.address}
              onClick={openConnectModal}
              d="flex"
              flexWrap="wrap"
              flexDirection="row"
              isLoading={loading}
              as="div"
              cursor="pointer"
              {...rest}
            >
              <Text flex="1" textAlign="left" data-cy={`${connectionName}-login-button`}>
                {walletName ? walletName : overrideName ?? name}
              </Text>
              <Box
                flexBasis="0"
                display="flex"
                flexDirection="row"
                width="100%"
                alignItems="center"
              >
                <Text fontSize={16} fontWeight="normal" pr="0.5em">
                  Connect
                </Text>
              </Box>
            </ConnectionContainerButton>

            {account?.address && !loading && (
              <Box d="flex">
                <Button
                  colorScheme={color}
                  fontSize="inherit"
                  fontWeight="normal"
                  onClick={() => {
                    walletSignin()
                  }}
                  flex={1}
                  textAlign="left"
                  justifyContent="flex-start"
                >
                  <Icon as={FiPlus} mr={1} /> Verify {account.address.slice(0, 7) + "..."}
                </Button>

                <Button
                  textAlign="left"
                  ml={2}
                  colorScheme={color}
                  fontSize="inherit"
                  // _hover={{ color: "red" }}
                  fontWeight="normal"
                  onClick={() => {
                    disconnect()
                  }}
                >
                  <Icon as={FiX} mr={1} /> Reset
                </Button>
              </Box>
            )}
          </>
        )}
      </ConnectButton.Custom>
    )
  }

  return (
    <ConnectionContainerButton
      leftIcon={walletName ? undefined : <ConnectionIcon />}
      colorScheme={color}
      isFullWidth={true}
      size="lg"
      // h={["2em", "2em", "2.2em"]}
      py="0.4em"
      h="auto"
      fontSize={["1.2em", "1.4em", "1.6em"]}
      variant={profile ? "outline" : "solid"}
      borderRadius="4px"
      justifyContent={profile ? "left" : "center"}
      bg={profile ? color + ".50" : undefined}
      px={3}
      borderColor={connectionName === "eth" ? "gray.500" : undefined}
      onClick={profile ? () => setOpen(!open) : login}
      d="flex"
      flexWrap="wrap"
      flexDirection="row"
      isLoading={loading}
      as="div"
      cursor="pointer"
      {...rest}
    >
      <Text flex="1" textAlign="left">
        {walletName ? walletName : overrideName ?? name}
      </Text>
      <Box flexBasis="0" display="flex" flexDirection="row" width="100%" alignItems="center">
        <NormalLinkIf href={url && profile && url(profile)} target="_blank">
          <Text fontSize={16} fontWeight="normal" pr="0.5em">
            {profile ? profile.nick || "connected" : <>Connect</>}
          </Text>
        </NormalLinkIf>
      </Box>
      <Box h={0} flexBasis="100%"></Box> {/* similar to float break */}
      <Collapse startingHeight="0em" in={open && !!profile} style={{ width: "100%" }}>
        <Box w="full" fontSize={[12, 14]}>
          <Box
            my={2}
            borderTopWidth={1}
            borderTopColor={`${color}.400`}
            opacity={0.3}
            width="full"
          ></Box>

          {!walletName && profile && (connectionName !== "eth" || !noWalletDisconnect) && (
            <ConnectionOptions
              connectionId={profile.connectionId}
              connection={connectionName}
              color={color}
              overrideName={overrideName}
            />
          )}
        </Box>
      </Collapse>
    </ConnectionContainerButton>
  )
}
