import https from "https"

// @ts-ignore
if (!https._requestLogPatched) {
  console.log("Patching https to log discord requests...")

  const original = https.request
  type ReqType = typeof https.request
  // @ts-ignore
  const loggedReq: ReqType = function (arg1: https.RequestOptions, arg2, arg3) {
    if (arg1?.host === "discord.com") {
      console.log(`Discord Request ${arg1.method} ${arg1.path}`)
    }
    // @ts-ignore
    return original(arg1, arg2, arg3)
  }

  // @ts-ignore
  https._requestLogPatched = true

  https.request = loggedReq
}
