import { useEffect, useRef } from "react"
import { TelegramUserAuthData } from "app/users/mutations/loginWithTelegram"

export const TelegramLogin = ({
  dataOnauth,
}: {
  dataOnauth: (user: TelegramUserAuthData) => void
}) => {
  const scriptRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    window["TelegramLoginWidget"] = {
      dataOnauth: (user) => dataOnauth(user),
    }

    const script = document.createElement("script")
    script.src = "https://telegram.org/js/telegram-widget.js?19"
    script.setAttribute("data-telegram-login", process.env.NEXT_PUBLIC_TELEGRAM_BOT_USERNAME!)
    script.setAttribute("data-size", "large")

    script.setAttribute("data-request-access", "write")

    script.setAttribute("data-onauth", "TelegramLoginWidget.dataOnauth(user)")

    script.async = true
    scriptRef.current?.appendChild(script)
  }, [])

  return <div style={{ width: "100%" }} ref={scriptRef}></div>
}
