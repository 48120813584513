import { SiweMessage } from "siwe"

export const createSiweMessage = (domain, origin, address, nonce, statement) => {
  const message = new SiweMessage({
    domain,
    address,
    statement,
    uri: origin,
    version: "1",
    chainId: 1,
    nonce,
  })
  return message.prepareMessage()
}
