import AddWallet from "app/users/mutations/addWallet"
import { useToast } from "@chakra-ui/react"
import { createSiweMessage } from "app/lib/siwe"
import { buildMessage, buildTypedData } from "app/lib/walletSign"
import { useMutation } from "blitz"
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react"
import { useAccount, useDisconnect, useSignMessage } from "wagmi"
import { isMobile } from "app/lib/isMobile"
import { UserContext } from "../layouts/Layout"
import { WalletAlreadyOwned } from "app/errors/ConnectionError"
import { OrgContext } from "app/org/components/orgPage"

const useWalletSignin = () => {
  const { org, refetch: refetchOrg } = useContext(OrgContext)!
  const { user, refetch: refetchUser, nonce } = useContext(UserContext)!

  const [addWallet, { isLoading: addWalletIsLoading }] = useMutation(AddWallet)
  const { signMessageAsync, reset: resetMessageSigning } = useSignMessage()

  const [waitingForSign, setWaitingForSign] = useState(false)

  const mobile = useMemo(() => isMobile(), [])
  const toast = useToast()

  const { address } = useAccount()
  const { disconnect } = useDisconnect()
  useEffect(() => disconnect(), [])

  const previousAddressRef = useRef<string | undefined>(undefined)

  const ethProfile = user && user["eth"] && user["eth"][0]

  const walletSignin = useCallback(async () => {
    if (!org) return console.error("no org for walletSignIn")

    const SIGN_MODE = org.walletSignMode
    console.log(`Start sign with mode ${SIGN_MODE}`)

    if (!nonce) {
      toast({
        status: "error",
        title: `Could not connect wallet! No nonce.`,
        // description: "Logout and sign-in with it to remove the claim.",
        duration: 6000,
        isClosable: true,
      })
      return
    }

    const typedData = buildTypedData(nonce)

    if (!address) {
      console.error("Wallet sign in called without an address")
      return
    }

    let signature: string
    let siweMessage: string | undefined

    try {
      resetMessageSigning()

      setWaitingForSign(true)
      if (SIGN_MODE === "personal") {
        signature = await signMessageAsync({ message: buildMessage(typedData) })
      } else if (SIGN_MODE === "siwe") {
        const domain = window.location.host
        const origin = window.location.origin

        siweMessage = createSiweMessage(
          domain,
          origin,
          address,
          nonce,
          `Sign in with Ethereum to ${org!.name} using Noble.`
        )

        console.log({ siweMessage })
        signature = await signMessageAsync({ message: siweMessage })
      } else {
        throw new Error("No sign method " + SIGN_MODE)
      }
    } catch (e) {
      console.error("error using signer", e)
      return
    } finally {
      setWaitingForSign(false)
    }

    try {
      await addWallet({
        typedData,
        signature,
        address,
        signingMode: SIGN_MODE,
        orgId: org.id,
        siweMessage,
      })
    } catch (e) {
      const isCancelledError = e && e.hasOwnProperty("silent")
      if (e instanceof WalletAlreadyOwned) {
        // todo show error
        toast({
          status: "warning",
          title: `Wallet already owned by another user`,
          description: "Logout and sign-in with it to remove the claim.",
          duration: 6000,
          isClosable: true,
        })
      } else if (isCancelledError) {
        //cancelled in flight mutation... do nothing
      } else {
        toast({
          status: "error",
          title: `Could not connect wallet!`,
          // description: "Logout and sign-in with it to remove the claim.",
          duration: 6000,
          isClosable: true,
        })
      }
    } finally {
      setTimeout(() => {
        refetchUser()
        refetchOrg()
      }, 100)
    }
  }, [address])

  useEffect(() => {
    const previousAddress = previousAddressRef.current

    if (!mobile && !previousAddress && address && !ethProfile) {
      setTimeout(() => {
        walletSignin()
      }, 600)
    }

    previousAddressRef.current = address
  }, [address])

  return {
    walletSignin,
    addWalletIsLoading,
    waitingForSign,
  }
}

export default useWalletSignin
