import "@rainbow-me/rainbowkit/styles.css"

import { ChevronDownIcon } from "@chakra-ui/icons"
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Button,
  Container,
  Image,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Stack,
  useToast,
} from "@chakra-ui/react"
import createCache from "@emotion/cache"
import { CacheProvider, css, Global } from "@emotion/react"

import { setUser } from "@sentry/nextjs"
import logout from "app/auth/mutations/logout"
import getCurrentUser, {
  CurrentUser,
  CurrentUserResponse,
  GetCurrentUserT,
} from "app/users/queries/getCurrentUser"
import { Head, Router, useMutation, useQuery, useRouter, useRouterQuery } from "blitz"
import React, { createContext, ReactNode, useEffect, Suspense, useRef, useMemo } from "react"

const emotionCache = createCache({
  nonce: "MYfIRjdcGPZ0aCrChzzlSYwvERjcAtiY8sHZEzZXvk=",
  key: "noble",
})

type LayoutProps = {
  title?: string
  children: ReactNode
}

type UseQueryT = ReturnType<typeof useQuery>
type UserContextT = {
  user?: CurrentUser
  refetch: UseQueryT[1]["refetch"]
  nonce?: string
  isHuman?: boolean
} | null
export const UserContext = createContext<UserContextT>(null)

const Layout = ({ title, children }: LayoutProps) => {
  // TODO HANDLE CATCH
  const [_user, { isLoading, refetch }] = useQuery(
    getCurrentUser,
    {},
    {
      suspense: false,
      retry: true,
    }
  )

  const { authError } = useRouterQuery()
  const router = useRouter()
  const toast = useToast()
  const { user, nonce, isHuman, githubCommit } = _user || {}
  const previousGithubCommit = useRef<string | null>(null)
  useEffect(() => {
    if (githubCommit && !previousGithubCommit.current) previousGithubCommit.current = githubCommit
    else if (
      previousGithubCommit.current &&
      githubCommit &&
      previousGithubCommit.current != githubCommit
    ) {
      window.location.reload()
    }
  }, [githubCommit])

  useEffect(() => {
    setUser(user ? { id: user.id } : null)
  }, [user])

  useEffect(() => {
    if (authError) {
      toast({
        status: "error",
        title: `Error`,
        description: (authError as string).replace(/^Error\: /, ""),
        duration: 20000,
        isClosable: true,
      })
      router.replace(router.asPath.split("?")[0]!, undefined, { shallow: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authError])

  return (
    <Suspense
      fallback={
        <Stack>
          <Skeleton height="120px"></Skeleton>
        </Stack>
      }
    >
      <CacheProvider value={emotionCache}>
        <UserContext.Provider value={{ user, refetch, nonce, isHuman }}>
          <Head>
            <title>{title && title + " | "} Noble</title>
            {/* <link rel="icon" href="/favicon.ico" /> */}
            <link rel="stylesheet" href="https://rsms.me/inter/inter.css"></link>
          </Head>
          <Global
            styles={css`
              html {
                font-family: "Inter", sans-serif;
              }
              @supports (font-variation-settings: normal) {
                html {
                  font-family: "Inter var", sans-serif;
                }
              }
            `}
          />

          <Container maxW="container.lg" p={5} height="100%" d="flex" flexDir="column">
            {/* <ErrorAlert authError={authError as string} /> */}

            {isLoading ? (
              <Stack>
                <Skeleton height="40px"></Skeleton>
              </Stack>
            ) : (
              children
            )}
          </Container>
        </UserContext.Provider>
      </CacheProvider>
    </Suspense>
  )
}

const ErrorAlert = ({ authError }: { authError: string }) => {
  if (typeof window === undefined || !authError) {
    return null
  }
  return (
    <Alert status="error" py={6} h={10}>
      <AlertIcon />
      <AlertTitle mr={2}>Error: </AlertTitle>
      <AlertDescription>{authError.replace(/^Error\: /, "")}</AlertDescription>
    </Alert>
  )
}

export default Layout
