import { useContext, useEffect, useRef, useState } from "react"
import { Text, Box, Collapse } from "@chakra-ui/react"

import { useQRCode } from "next-qrcode"
import { Image, useMutation } from "blitz"
import { ConnectionContainerButton } from "./status"

import brightIdLogo from "/public/brightId.png"
import startBrightIDVerificationPolling from "app/brightid-verifications/mutations/startBrightIDVerificationPolling"
import stopBrightIDVerificationPolling from "app/brightid-verifications/mutations/stopBrightIDVerificationPolling"
import checkBrightIDVerificationStatus from "app/brightid-verifications/mutations/checkBrightIDVerificationStatus"
import useInterval from "app/core/hooks/useInterval"
import { OrgContext } from "./orgPage"
import { RefreshMembershipParamsType } from "app/users/mutations/refreshMembership"

const brightIDNodeUrl = process.env.BLITZ_PUBLIC_BRIGHTID_NODEURL || "http:%2f%2fnode.brightid.org"
const brightIDContext = process.env.BLITZ_PUBLIC_BRIGHTID_CONTEXT || "Noble" //TODO

const brightIDClientSidePollingMs = 5000

const BrightIDVerificationLink: React.FC<{
  userId: string
  contextId: string
  orgId: string
  addressId: string
  refreshMembership: (variables: RefreshMembershipParamsType) => Promise<{ jobId: string | null }>
}> = ({ userId, contextId, orgId, addressId, refreshMembership }) => {
  const [open, setOpen] = useState(false)
  const [isVerified, setIsVerified] = useState(false)
  const { refetch: refetchOrg } = useContext(OrgContext)!

  const [startBrightIDVerificationPoll, { isLoading: startBrightIDVerificationPollIsLoading }] =
    useMutation(startBrightIDVerificationPolling)
  const [stopBrightIDVerificationPoll, { isLoading: stopBrightIDVerificationPollIsLoading }] =
    useMutation(stopBrightIDVerificationPolling)

  const [checkBrightIDVerification, { isLoading: checkBrightIDVerificationLoading }] = useMutation(
    checkBrightIDVerificationStatus
  )

  useInterval(async () => {
    if (!open) {
      return
    }

    const status = await checkBrightIDVerification({ contextId, orgId, addressId })
    if (status?.status === "COMPLETED") {
      setIsVerified(true)
      setOpen(false)
      await refreshMembership({ orgId })
      await refetchOrg()
      return
    }

    //TODO: check status and perhaps show some sort of animation to inform the user that they have to do something
  }, brightIDClientSidePollingMs)

  useEffect(() => {
    async function startPolling(contextId: string) {
      await startBrightIDVerificationPoll({ contextId, orgId, addressId })
    }
    async function stopPolling(contextId: string) {
      await stopBrightIDVerificationPoll({ contextId, orgId, addressId })
    }
    if (!contextId) {
      return
    }
    if (open) {
      startPolling(contextId)
    } else if (!isVerified) {
      stopPolling(contextId)
    }
  }, [
    open,
    contextId,
    orgId,
    addressId,
    isVerified,
    startBrightIDVerificationPoll,
    stopBrightIDVerificationPoll,
  ])

  const link = `https://app.brightid.org/link-verification/${brightIDNodeUrl}/${brightIDContext}/${contextId}`

  const deepLink = link.replace("https://app.brightid.org/", "brightid://")
  const { inputRef: qrCodeRef } = useQRCode<HTMLCanvasElement>({
    text: deepLink,
    options: {
      level: "H",
      margin: 2,
      scale: 4,
      width: 200,
      color: {
        dark: "#6572FF",
        light: "#FFFFFF",
      },
    },
  })

  const toggleOpen = (openState: boolean) => {
    if (
      !startBrightIDVerificationPollIsLoading &&
      !stopBrightIDVerificationPollIsLoading &&
      !checkBrightIDVerificationLoading
    ) {
      setOpen(openState)
    }
  }

  return (
    <>
      <ConnectionContainerButton
        // leftIcon={<SiEthereum />}
        colorScheme={"yellow"}
        isFullWidth={true}
        size="lg"
        // h={["2em", "2em", "2.2em"]}
        py="0.4em"
        h="auto"
        fontSize={["1.2em", "1.4em", "1.6em"]}
        variant={"solid"}
        borderRadius="4px"
        justifyContent={"left"}
        // bg={profile ? color + ".50" : undefined}
        // px={3}
        // borderColor={connectionName === "eth" ? "gray.500" : undefined}
        // onClick={profile ? () => setOpen(!open) : login}
        onClick={() => toggleOpen(!open)}
        d="flex"
        flexWrap="wrap"
        flexDirection="row"
        isLoading={startBrightIDVerificationPollIsLoading}
        as="div"
        cursor="pointer"
      >
        <Box display="flex" alignItems={"left"}>
          <Image src={brightIdLogo} width={150} height={47} alt="BrightID" placeholder={"blur"} />
        </Box>
        {isVerified && (
          <Text
            spacing={[1, 2]}
            fontWeight="normal"
            color="green.300"
            pb="0.25em"
            pt="0.5em"
            textAlign="right"
          >
            Verified
          </Text>
        )}
        {!open && <Box h={0} flexBasis="100%"></Box>}
        <Box
          flexBasis="0"
          display="flex"
          flexDirection="row"
          flexGrow={1}
          width="100%"
          alignItems="center"
          justifyContent={"space-between"}
          fontSize={[12, 14]}
        >
          <Collapse startingHeight="0em" in={open} style={{ width: "100%" }}>
            <Box
              w="full"
              fontSize={[12, 14]}
              pl={[1, 1, 1.5]}
              mt={1}
              alignItems={"center"}
              alignContent={"center"}
              display={"flex"}
              flexDirection={"column"}
            >
              <Text spacing={[1, 2]} fontWeight="normal" pb="0.25em" pt="0.5em" textAlign="left">
                {contextId}
              </Text>
              <Box backgroundColor={"white"} width={"200px"}>
                <a href={link} target={"_top"}>
                  <canvas ref={qrCodeRef} />
                </a>
              </Box>
              <Text spacing={[1, 2]} fontWeight="normal" pb="0.25em" pt="0.5em" textAlign="left">
                Scan/tap on this QR code to verify your identity
              </Text>
            </Box>
          </Collapse>
        </Box>
      </ConnectionContainerButton>
    </>
  )
}

export default BrightIDVerificationLink
