import { ChevronDownIcon } from "@chakra-ui/icons"
import {
  Box,
  Button,
  Heading,
  Menu,
  MenuButton,
  Skeleton,
  Image,
  MenuList,
  MenuItem,
} from "@chakra-ui/react"
import logout from "app/auth/mutations/logout"
import { CurrentUser } from "app/users/queries/getCurrentUser"
import { useMutation, useRouter } from "blitz"
import React from "react"

export const UserHeader = ({
  user,
  isLoading,
}: {
  user?: CurrentUser | null
  isLoading: boolean
}) => {
  // const { toggleColorMode } = useColorMode()
  return (
    <Box display="flex" alignItems="center" pb="3">
      <Heading as="h1" fontSize={[30, 50, 70]} flex="1" my={["-0.3em", "-0.2em", "0em"]}></Heading>

      <Menu placement="bottom-end">
        {
          isLoading ? (
            <MenuButton
              h={14}
              borderRadius="full"
              px={5}
              as={Button}
              rightIcon={<ChevronDownIcon />}
            >
              &nbsp;
              {/* <Skeleton boxSize="1.5rem" borderRadius="full" mt="0.2em" d="inline-block"></Skeleton> */}
              <Skeleton
                width="6em"
                height="1.5em"
                ml="0.5em"
                mt="0.2em"
                d="inline-block"
              ></Skeleton>
            </MenuButton>
          ) : user ? (
            <UserMenu user={user} />
          ) : null
          // <IconButton
          //   icon={<FiSun />}
          //   aria-label="toggle dark mode"
          //   onClick={() => toggleColorMode()}
          // />
        }
      </Menu>
    </Box>
  )
}

export const UserMenu = ({ user }: { user: CurrentUser }) => {
  const picture = user.twitter?.[0]?.photo //TODO: dumb for now
  const [logoutMutation] = useMutation(logout)
  // const { toggleColorMode } = useColorMode()
  const router = useRouter()

  return (
    <>
      <MenuButton
        as={Button}
        rightIcon={<ChevronDownIcon />}
        h={14}
        borderRadius="full"
        px={5}
        data-cy="header-user-nick"
      >
        {picture && (
          <Image
            boxSize="1.5rem"
            borderRadius="full"
            src={picture}
            alt="Profile picture"
            mr="12px"
            display="inline"
            h={10}
            w={10}
          />
        )}
        {user.nick?.value || "you"}
      </MenuButton>

      <MenuList>
        <MenuItem
          onClick={async () => {
            await logoutMutation()
            delete router.query.authError
          }}
        >
          Logout
        </MenuItem>
      </MenuList>
    </>
  )
}
