import { OrgPreferencesObject } from "app/org/queries/getOrg"
import db, { Org } from "db"
import { serializeOAuthState } from "./oauthState"
import { Connection } from "./requirements/types"

/**
 * isLocalEnv helps us dealing with insecure http server running on dev environment
 * @param url
 * @returns {Boolean}
 */

export const isLocalEnv = () => {
  return process.env.NODE_ENV === "development"
}

export const BLITZ_PUBLIC_NOBLE_PLACE_HOST =
  process.env.BLITZ_PUBLIC_NOBLE_PLACE_HOST || (isLocalEnv() ? "localhost:3000" : "noble.place")

export function isNoblePlaceUrl(url: string): boolean {
  return url.toLowerCase().startsWith(BLITZ_PUBLIC_NOBLE_PLACE_HOST)
}

export function getNoblePlaceUrl(): string {
  let protocol = "https://"

  if (isLocalEnv()) protocol = "http://"

  return `${protocol}${BLITZ_PUBLIC_NOBLE_PLACE_HOST}`
}

export function getNoblePlaceSlugUrl(slug: string): string {
  return `${getNoblePlaceUrl()}/${slug}`
}

export const getNoblePlaceOrgSlugUrl = (org?: Org | null) => {
  return getNoblePlaceSlugUrl(org?.slug || "")
}

export const getNoblePlaceOrgSlugUrlById = async (orgId?: string | null) => {
  if (!orgId) {
    return null
  }
  const org = await db.org.findUnique({ where: { id: orgId } })
  return getNoblePlaceOrgSlugUrl(org)
}

export function getStrategyCallbackURL(
  baseUrl: string,
  strategy: "twitter" | "discord" | "nifty" | "google" | "conde",
  orgId?: string
): string {
  return `${baseUrl}/api/redirect-to-oauth-callback/${strategy}${orgId ? `?orgId=${orgId}` : ""}`
}

export function getLoginLink(
  connection: Connection,
  orgId: string,
  redirectUrl: string | undefined,
  token?: string,
  args?: { googleOrgId?: string; identifyBot?: boolean }
): string {
  const state = serializeOAuthState({
    orgId: orgId as string,
    googleOrgId: args?.googleOrgId ? args.googleOrgId : undefined,
  })

  let params = new URLSearchParams()
  params.append("state", state as string)
  params.append("orgId", orgId as string)

  if (redirectUrl) params.append("redirectUrl", redirectUrl as string)
  if (token) params.append("token", token)
  if (args?.identifyBot) params.append("identifyBot", "1")

  return `/api/auth/${connection}?${params.toString()}`
}

export const getShouldUseCustomDomain = (org: Org) => {
  const { redirectToSlug } = org.preferences as OrgPreferencesObject
  return !!org.customDomain && !redirectToSlug
}

export const getOrgBaseUrl = (org: Org, forceIncludeSlug?: boolean) => {
  const { customDomain, slug } = org
  const protocol = isLocalEnv() ? "http://" : "https://"
  const shouldUseCustomDomain = getShouldUseCustomDomain(org)
  const domain = shouldUseCustomDomain ? customDomain : BLITZ_PUBLIC_NOBLE_PLACE_HOST
  if (forceIncludeSlug || !shouldUseCustomDomain) return `${protocol}${domain}/${slug}`
  return protocol + domain
}

export const getEditorLink = (org: Org) => {
  return getOrgBaseUrl(org, true) + "/editor"
}

export const slugRegex = new RegExp("^[a-z0-9]+(?:-[a-z0-9]+)*$")

export const slugFormater = (value: string) =>
  value
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "")

export const getNotionPageUrl = (slug: string, notionPageId: string) =>
  `${getNoblePlaceUrl()}/notion/${slug}/${notionPageId}`
