import { Button, useToast } from "@chakra-ui/react"
import VerifyCaptcha from "app/captcha-completions/mutations/verifyCaptcha"
import { UserContext } from "app/core/layouts/Layout"
import { useMutation } from "blitz"
import { useContext, useEffect, useState } from "react"
import CreateInviteLink, { InviteLink } from "../mutations/createInviteLink"
import Captcha from "./captcha"

const SignedOutDiscordInvite: React.FC<{
  orgId: string
}> = ({ orgId }) => {
  const [showCaptcha, setShowCaptcha] = useState<boolean>(false)
  const { refetch: refetchUser, isHuman } = useContext(UserContext)!

  const toast = useToast()

  const [verifyCaptcha] = useMutation(VerifyCaptcha)
  const [createInviteLink, { isLoading: createInviteLinkLoading, isError, data }] =
    useMutation(CreateInviteLink)
  const inviteLink = data && "url" in data ? data.url : null

  const getSignedOutDiscordInvite = async () => {
    const result = await createInviteLink({ orgId })
    if (typeof window != "undefined" && result && "url" in result) {
      const w = window.open(result.url)
    }
    return
  }

  const verify = async (token: string) => {
    const success = await verifyCaptcha({ captchaToken: token, orgId })
    if (success) {
      setShowCaptcha(false)
      await refetchUser()
      await getSignedOutDiscordInvite()
    }
  }

  useEffect(() => {
    if (isError) {
      toast({
        status: "error",
        title: `Error`,
        description: "There was an error when trying to create your invite",
        duration: 6000,
        isClosable: true,
      })
    }
  }, [isError, toast])

  const handleClick = () => {
    if (isHuman === false) {
      setShowCaptcha(true)
    } else if (isHuman === true) {
      getSignedOutDiscordInvite()
    }
  }

  const showLink = !!inviteLink
  const showButton = !showLink && !showCaptcha

  return (
    <>
      {showButton && (
        <Button
          backgroundColor="transparent"
          textDecoration="underline"
          isLoading={createInviteLinkLoading}
          isFullWidth
          onClick={handleClick}
          disabled={typeof isHuman !== "boolean"}
        >
          Just Get Discord Invite
        </Button>
      )}
      {showCaptcha && <Captcha onVerify={verify} />}
      {showLink && (
        <Button
          as="a"
          backgroundColor="transparent"
          textDecoration="underline"
          isFullWidth
          href={inviteLink}
          target="_blank"
          rel="noreferrer"
        >
          Join Discord Server
        </Button>
      )}
    </>
  )
}
export default SignedOutDiscordInvite
