export const buildTypedData = (nonce: string) => ({
  message: "A Noble Sign",
  nonce,
  ts: new Date().toISOString(),
})

type TypedData = ReturnType<typeof buildTypedData>

export const buildMessage = ({ nonce, message, ts }: TypedData) =>
  `${message}.


nonce: ${nonce}.
time: ${ts}
`

export const TypedDataConfig = {
  type: {
    Login: [
      {
        name: "message",
        type: "string",
      },
      { name: "nonce", type: "string" },
      { name: "ts", type: "string" },
    ],
  },
  domain: {
    name: "Noble",
  },
}

export type SigningMode = "typedv4" | "typed" | "personal" | "sign" | "siwe"
